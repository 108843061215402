<template>
  <div class="style-box-setting">
    <div class="style-box-setting-header">
      <v-row>
        <v-col cols="3">
          <div class="title-header">
            Gestion des sociétes
          </div>
        </v-col>
        <v-col cols="1">
          <v-progress-circular
            class="ml-2"
            v-if="getLoadingSettingFilialeTh"
            indeterminate
            color="#704ad1"
          ></v-progress-circular>
        </v-col>
        <v-col cols="8">
          <div class="error-message ml-2 mr-2 mt-3">
            <div v-if="getErrorSettingFilialeTh">
              <ul v-if="Array.isArray(getErrorSettingFilialeTh)" class="mb-0">
                <li v-for="(e, index) in getErrorSettingFilialeTh" :key="index">
                  {{ e }}
                </li>
              </ul>
              <div v-else>{{ getErrorSettingFilialeTh }}</div>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
    <v-divider></v-divider>
    <div class="box-content">
      <div class="card-block-filter-setting">
        <div class="right-block-filter">
          <v-select
            placeholder="Séléctionner"
            :items="[...optionsTypeForFitre]"
            label="Type"
            v-model="typeFilter"
            outlined
            clearable
            @change="onChangeType"
            :persistent-placeholder="true"
            dense
            :menu-props="{ bottom: true, offsetY: true }"
            color="#704ad1"
            item-color="#704ad1"
          >
          </v-select>
          <v-select
            v-if="typeFilter === 'professionnel'"
            class="ml-1"
            placeholder="Séléctionner"
            :items="[...optionTypeSte]"
            label="Type de société"
            item-text="text"
            item-value="value"
            v-model="typeSte"
            outlined
            clearable
            @change="onChangeTypeSte"
            :persistent-placeholder="true"
            dense
            :menu-props="{ bottom: true, offsetY: true }"
            color="#704ad1"
            item-color="#704ad1"
          >
          </v-select>
        </div>
        <div class="left-block-filter">
          <div class="search-input">
            <search
              :value="searchValue"
              @changeSearchValue="changeSearchValue"
              hiddenContent="true"
            />
          </div>
          <v-tooltip bottom color="#311B92">
            <template v-slot:activator="{ on, attrs }">
              <div
                size="sm"
                class="block-icon mr-2"
                v-on="on"
                v-bind="attrs"
                v-if="checkPermission('GSGSELS')"
                @click.prevent.stop="exportFiles"
                :disabled="getShowNotifExportFile"
              >
                <font-awesome-icon
                  icon="cloud-download-alt"
                  :class="{ hidden: loadingExport }"
                />
                <v-progress-circular
                  v-if="loadingExport"
                  class="mr-2 mt-1"
                  indeterminate
                  width="2"
                  size="10"
                  color="#704ad1"
                ></v-progress-circular>
              </div>
            </template>
            <span>Exporter Liste des sociétés</span>
          </v-tooltip>
          <v-tooltip bottom color="#311B92">
            <template v-slot:activator="{ on, attrs }">
              <div
                class="block-icon-setting text-center mr-1"
                v-on="on"
                v-bind="attrs"
                @click.stop="handlClickActive('no')"
              >
                <font-awesome-icon icon="store" />
              </div>
            </template>
            <span>Société non supprimée</span>
          </v-tooltip>
          <v-tooltip bottom color="#311B92">
            <template v-slot:activator="{ on, attrs }">
              <div
                class="block-icon-setting text-center mr-1"
                v-on="on"
                v-bind="attrs"
                @click.stop="handlClickActive('yes')"
              >
                <font-awesome-icon icon="store-slash" />
              </div>
            </template>
            <span>Société supprimée</span>
          </v-tooltip>
          <v-tooltip bottom color="#311B92">
            <template v-slot:activator="{ on, attrs }">
              <div
                class="block-icon-setting text-center"
                v-on="on"
                v-bind="attrs"
                v-if="checkPermission('GSAS')"
                @click.prevent="handleModalFiliale('add', 'show')"
              >
                <font-awesome-icon icon="plus-circle" />
              </div>
            </template>
            <span>Ajouter société</span>
          </v-tooltip>
        </div>
      </div>
      <!--TABLE CONTENT-->
      <div class="table-gestion-content">
        <v-data-table
          v-model="selectedTable"
          :headers="computedFields"
          :items="computedSociete"
          class="table-setting"
          hide-default-footer
          :calculate-widths="false"
          :fixed-header="true"
          :items-per-page="perPage"
          hide-action
          :disable-sort="true"
          :no-data-text="
            getLoadingSettingFilialeTh
              ? 'Chargement en cours ...'
              : `Il n'y a aucun enregistrement à afficher`
          "
        >
          <!--NAME SOCIETE-->
          <template v-slot:[`item.name`]="{ item }">
            <td class="custom-cell">{{ item.name ? item.name : '-' }}</td>
          </template>
          <!--LOGO-->
          <template v-slot:[`item.logo`]="{ item }">
            <td class="custom-cell">
              <div class="mt-2" v-if="item && item.logo">
                <img class="image-preview" :src="item.logo" />
              </div>
              <div v-else>
                -
              </div>
            </td>
          </template>
          <!--TYPE SOCIETE-->
          <template v-slot:[`item.type_societe`]="{ item }">
            <td class="custom-cell">
              {{ item.type_societe ? item.type_societe : '-' }}
            </td>
          </template>
          <!--ACTIVE MASTER-->
          <template v-slot:[`item.active_master`]="{ item }">
            <td class="custom-cell">
              {{ item.active_master ? item.active_master : '-' }}
            </td>
          </template>
          <!--PRENOM-->
          <template v-slot:[`item.prenom`]="{ item }">
            <td class="custom-cell">{{ item.prenom ? item.prenom : '-' }}</td>
          </template>
          <!--NOM FAMILLE-->
          <template v-slot:[`item.nom_famille`]="{ item }">
            <td class="custom-cell">
              {{ item.nom_famille ? item.nom_famille : '-' }}
            </td>
          </template>
          <!--PIXEL ID-->
          <template v-slot:[`item.pixel_id`]="{ item }">
            <td class="custom-cell">
              {{ item.pixel_id ? item.pixel_id : '-' }}
            </td>
          </template>
          <!--ABREVIATION-->
          <template v-slot:[`item.abreviation`]="{ item }">
            <td class="custom-cell">
              {{ item.abreviation ? item.abreviation : '-' }}
            </td>
          </template>
          <!--TYPE-->
          <template v-slot:[`item.type`]="{ item }">
            <td class="custom-cell">{{ item.type ? item.type : '-' }}</td>
          </template>
          <!--ADRESSE-->
          <template v-slot:[`item.adresse`]="{ item }">
            <td class="custom-cell">{{ item.adresse ? item.adresse : '-' }}</td>
          </template>
          <!--EMAIL-->
          <template v-slot:[`item.email`]="{ item }">
            <td class="custom-cell">{{ item.email ? item.email : '-' }}</td>
          </template>
          <!--SIRET-->
          <template v-slot:[`item.siret`]="{ item }">
            <td class="custom-cell">{{ item.siret ? item.siret : '-' }}</td>
          </template>

          <!--RESPONSABLE-->
          <template v-slot:[`item.responsable`]="{ item }">
            <td
              class="custom-cell"
              v-if="item && item.responsables && item.responsables.length > 0"
            >
              <li
                v-for="(responsable, index) in item.responsables"
                :key="index"
              >
                {{ responsable.name }}
              </li>
            </td>
            <td class="custom-cell" v-else>
              -
            </td>
          </template>

          <!--DEPOT-->
          <template v-slot:[`item.depot`]="{ item }">
            <td
              class="custom-cell"
              v-if="item && item.depots && item.depots.length > 0"
            >
              <li v-for="(depot, index) in item.depots" :key="index">
                {{ depot.depot ? depot.depot : '-' }}
              </li>
            </td>
            <td class="custom-cell" v-else>
              -
            </td>
          </template>

          <!--RESPONSABLE DEPOT-->
          <template v-slot:[`item.responsable_depot`]="{ item }">
            <td
              class="custom-cell"
              v-if="item && item.depots && item.depots.length > 0"
            >
              <li v-for="(depot, index) in item.depots" :key="index">
                {{ depot.responsable_depot ? depot.responsable_depot : '-' }}
              </li>
            </td>
            <td class="custom-cell" v-else>
              -
            </td>
          </template>
          <!--CIVILITE-->
          <template v-slot:[`item.civilite`]="{ item }">
            <td class="custom-cell">
              {{ item.civilite ? item.civilite : '-' }}
            </td>
          </template>
          <!--SIREN-->
          <template v-slot:[`item.siren_value`]="{ item }">
            <td class="custom-cell">
              {{ item.siren_value ? item.siren_value : '-' }}
            </td>
          </template>
          <!--TVA-->
          <template v-slot:[`item.tva_value`]="{ item }">
            <td class="custom-cell">
              {{ item.tva_value ? item.tva_value : '-' }}
            </td>
          </template>
          <!--CAPITAL-->
          <template v-slot:[`item.capital`]="{ item }">
            <td class="custom-cell">{{ item.capital ? item.capital : '-' }}</td>
          </template>
          <!--RCS-->
          <template v-slot:[`item.rcs`]="{ item }">
            <td class="custom-cell">{{ item.rcs ? item.rcs : '-' }}</td>
          </template>
          <!--CODE POSTAL-->
          <template v-slot:[`item.code_postal`]="{ item }">
            <td class="custom-cell">
              {{ item.code_postal ? item.code_postal : '-' }}
            </td>
          </template>
          <!--SITE INTERNET-->
          <template v-slot:[`item.site_internet`]="{ item }">
            <td class="custom-cell">
              {{ item.site_internet ? item.site_internet : '-' }}
            </td>
          </template>
          <!--VILLE-->
          <template v-slot:[`item.ville`]="{ item }">
            <td class="custom-cell">{{ item.ville ? item.ville : '-' }}</td>
          </template>
          <!--PAYS-->
          <template v-slot:[`item.pays`]="{ item }">
            <td class="custom-cell">{{ item.pays ? item.pays : '-' }}</td>
          </template>
          <!--IBAN-->
          <template v-slot:[`item.iban`]="{ item }">
            <td class="custom-cell">{{ item.iban ? item.iban : '-' }}</td>
          </template>
          <!--BANQUE-->
          <template v-slot:[`item.banque`]="{ item }">
            <td class="custom-cell">{{ item.banque ? item.banque : '-' }}</td>
          </template>
          <!--SWIFT-->
          <template v-slot:[`item.swift`]="{ item }">
            <td class="custom-cell">{{ item.swift ? item.swift : '-' }}</td>
          </template>
          <!--FAX-->
          <template v-slot:[`item.fax`]="{ item }">
            <td class="custom-cell">{{ item.fax ? item.fax : '-' }}</td>
          </template>
          <!--TELEPHONE-->
          <template v-slot:[`item.telephone`]="{ item }">
            <td class="custom-cell">
              {{ item.telephone ? item.telephone : '-' }}
            </td>
          </template>
          <!--TYPE FOOTER-->
          <template v-slot:[`item.footer_type`]="{ item }">
            <td class="custom-cell">
              {{ item.footer_type ? item.footer_type : '-' }}
            </td>
          </template>
          <!--FOOTER-->
          <template v-slot:[`item.footer`]="{ item }">
            <td class="custom-cell">{{ item.footer ? item.footer : '-' }}</td>
          </template>
          <!--TELEPHONE PORTABLE-->
          <template v-slot:[`item.telephone_portable`]="{ item }">
            <td class="custom-cell">
              {{ item.telephone_portable ? item.telephone_portable : '-' }}
            </td>
          </template>
          <!--DESCRIPTION-->
          <template v-slot:[`item.description`]="{ item }">
            <td class="custom-cell">
              {{ item.description ? item.description : '-' }}
            </td>
          </template>
          <!--PAYMENT CONDITION-->
          <template v-slot:[`item.payment_condition_name`]="{ item }">
            <td class="custom-cell">
              {{
                item.payment_condition_name ? item.payment_condition_name : '-'
              }}
            </td>
          </template>
          <!--PAYMENT TYPE-->
          <template v-slot:[`item.payment_type`]="{ item }">
            <td class="custom-cell">
              {{ item.payment_type ? item.payment_type : '-' }}
            </td>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <td class="custom-cell sticky-header-end">
              <v-menu
                origin="top left"
                :close-on-content-click="false"
                left
                offset-x
              >
                <template v-slot:activator="{ on, attrs }">
                  <div
                    class="block-icon-reglement-fac block-setting"
                    v-on="on"
                    v-bind="attrs"
                  >
                    <font-awesome-icon icon="wrench" />
                  </div>
                </template>
                <v-card>
                  <v-card-text class="body-card center-text">
                    <v-list-item class="block-action">
                      <v-list-item-content>
                        <div class="d-flex actions-style">
                          <div
                            v-if="deleted === 'yes'"
                            class="block-icon-reglement-fac mr-1"
                            @click.prevent.stop="handleClickRestaurer(item)"
                            title="Restaurer société"
                          >
                            <font-awesome-icon icon="arrow-alt-circle-left" />
                          </div>
                          <div
                            v-if="checkPermission('GSMS') && deleted === 'no'"
                            class="block-icon-reglement-fac mr-1"
                            @click.prevent.stop="handleClickUpdate(item)"
                            title="Modifier société"
                          >
                            <font-awesome-icon icon="pencil-alt" />
                          </div>
                          <div
                            v-if="checkPermission('GSDS') && deleted === 'no'"
                            class="block-icon-reglement-fac mr-1"
                            @click.prevent.stop="handleClickDuplicate(item)"
                            title="Dupliquer société"
                          >
                            <font-awesome-icon icon="copy" />
                          </div>
                          <div
                            v-if="checkPermission('GSSS') && deleted === 'no'"
                            class="block-icon-reglement-fac mr-1"
                            @click.prevent.stop="deleteFiliale(item)"
                            title="Supprimer une société"
                          >
                            <font-awesome-icon icon="trash" />
                          </div>
                          <sequences
                            v-if="
                              checkPermission('GSGS') &&
                                !computedCheckFilialeParticulier(item) &&
                                deleted === 'no'
                            "
                            :item="item"
                          />
                          <ribs
                            v-if="
                              checkPermission('GSGR') &&
                                !computedCheckFilialeParticulier(item) &&
                                deleted === 'no'
                            "
                            :item="item"
                          />
                          <societeFactures
                            :item="item"
                            v-if="
                              checkPermission('GSGSF') &&
                                !computedCheckFilialeParticulier(item) &&
                                deleted === 'no'
                            "
                          />
                          <extraMails
                            v-if="
                              checkPermission('GSGEM') &&
                                !computedCheckFilialeParticulier(item) &&
                                deleted === 'no'
                            "
                            :item="item"
                          />
                          <pseudos
                            v-if="
                              checkPermission('GSGP') &&
                                !computedCheckFilialeParticulier(item) &&
                                deleted === 'no'
                            "
                            :item="item"
                          />
                          <masterFiliales
                            v-if="
                              item.type === 'master filiale' &&
                                checkPermission('GSLFPM') &&
                                !computedCheckFilialeParticulier(item) &&
                                deleted === 'no'
                            "
                            :item="item"
                          />
                          <planTiers
                            v-if="
                              checkPermission('GSGDPT') &&
                                !computedCheckFilialeParticulier(item) &&
                                deleted === 'no'
                            "
                            :item="item"
                          ></planTiers>
                        </div>
                      </v-list-item-content>
                    </v-list-item>
                  </v-card-text>
                </v-card>
              </v-menu>
            </td>
          </template>
        </v-data-table>
      </div>
    </div>
    <!--FOOTER-->
    <div class="footer-style-table">
      <div class="text-center pagination-table ">
        <v-pagination
          v-model="page"
          :length="totalPages"
          @input="pagination"
          :total-visible="7"
        ></v-pagination>
      </div>
      <div class="select-input-vuetify mr-2">
        <v-select
          v-model="perPage"
          :items="perPageList"
          @change="changePerPage"
          label="Eléments par page"
          color="#704ad1"
          item-color="#704ad1"
          outlined
          dense
          hide-details
          no-data-text="Aucun élément trouvé"
          :menu-props="{
            left: true,
            offsetY: true,
            closeOnClick: true,
            closeOnContentClick: true
          }"
        ></v-select>
      </div>
    </div>
    <!--MODAL RESTAURER FILIALE-->
    <v-dialog
      v-model="restaurerModal"
      persistent
      max-width="600px"
      :scrollable="true"
      :hide-overlay="true"
      no-click-animation
      content-class="custom-vuetify-dialog-add"
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">Restaurer une société </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="handleModalFiliale('restaurer', 'hide')"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr"></v-divider>
        <v-card-text class="body-card">
          <div class="mt-4 ml-3 text-center">
            <p class="dark-class">
              Êtes-vous sûr de vouloir restaurer cet société
              <strong>
                {{ steToRestaurer ? steToRestaurer.name : '' }}
              </strong>
            </p>
          </div>
          <div v-if="error" class="message-error-modal">
            <ul v-if="Array.isArray(error)" class="mb-0">
              <li v-for="(e, index) in error" :key="index">
                {{ e }}
              </li>
            </ul>
            <div class="message-error-modal" v-else>{{ error }}</div>
          </div>
        </v-card-text>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            text
            type="submit"
            @click.prevent="handleRestaurer"
            :loading="isLoading"
            :disabled="isLoading"
            :class="{ loader: isLoading }"
          >
            Confirmer
          </v-btn>
          <v-btn text @click="handleModalFiliale('restaurer', 'hide')">
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--MODAL DELETE FILIALE-->
    <v-dialog
      v-model="deleteModalFiliale"
      persistent
      max-width="600px"
      :scrollable="true"
      :hide-overlay="true"
      no-click-animation
      content-class="custom-vuetify-dialog-add"
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">Supprimer une société </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="handleModalFiliale('delete', 'hide')"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr"></v-divider>
        <v-card-text class="body-card">
          <div class="mt-4 ml-3 text-center">
            <p class="dark-class">
              Êtes-vous sur de vouloir supprimer cette société
              <strong>
                {{ filialeToDelete ? filialeToDelete.name : '-' }}
              </strong>
            </p>
          </div>
          <div v-if="error" class="message-error-modal">
            <ul v-if="Array.isArray(error)" class="mb-0">
              <li v-for="(e, index) in error" :key="index">
                {{ e }}
              </li>
            </ul>
            <div class="message-error-modal" v-else>{{ error }}</div>
          </div>
        </v-card-text>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            text
            type="submit"
            @click.prevent="handleDeleteFiliale"
            :loading="isLoading"
            :disabled="isLoading"
            :class="{ loader: isLoading }"
          >
            Confirmer
          </v-btn>
          <v-btn text @click="handleModalFiliale('delete', 'hide')">
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--MODAL ADD FILIALE-->
    <v-dialog
      v-model="addModalFiliale"
      persistent
      max-width="1500px"
      :scrollable="true"
      :hide-overlay="true"
      no-click-animation
      content-class="v-dialog.custom-vuetify-dialog-list"
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">Ajouter une société </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="handleModalFiliale('add', 'hide')"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr"></v-divider>
        <v-card-text class="body-card">
          <form class="form-add">
            <v-row>
              <v-col cols="3">
                <v-select
                  placeholder="Séléctionnez"
                  :items="options"
                  class="select-menu"
                  label="Type *"
                  :persistent-placeholder="true"
                  v-model="filialeToAdd.type"
                  outlined
                  dense
                  :auto-focus="false"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#704ad1"
                  item-color="#704ad1"
                  @change="onChangeTypeForm(filialeToAdd.type)"
                >
                </v-select>
              </v-col>
              <v-col
                cols="2"
                v-if="!computedCheckFilialeParticulier(filialeToAdd)"
              >
                <v-checkbox
                  class="checked-global checked-top"
                  v-model="filialeToAdd.in_configs"
                  color="#704ad1"
                  :value="'oui'"
                  label="Configure une société"
                  :unchecked-value="'non'"
                  hide-details
                ></v-checkbox>
              </v-col>
              <v-col
                cols="2"
                v-if="!computedCheckFilialeParticulier(filialeToAdd)"
              >
                <v-checkbox
                  class="checked-global checked-top"
                  v-model="filialeToAdd.is_societe_support"
                  @change="onChangeSupport('add')"
                  color="#704ad1"
                  :value="true"
                  :unchecked-value="false"
                  label="Support"
                  hide-details
                ></v-checkbox>
              </v-col>
              <v-col
                cols="3"
                v-if="
                  filialeToAdd.is_societe_support == true &&
                    !computedCheckFilialeParticulier(filialeToAdd)
                "
              >
                <v-select
                  placeholder="Séléctionnez"
                  :items="getTypeSocieteSupportTh"
                  class="select-menu"
                  label="Type *"
                  :persistent-placeholder="true"
                  v-model="filialeToAdd.type_societe_support"
                  outlined
                  dense
                  :auto-focus="false"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#704ad1"
                  item-color="#704ad1"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row v-if="!computedCheckFilialeParticulier(filialeToAdd)">
              <v-col cols="5">
                <v-text-field
                  label="Nom de société *"
                  mess
                  :persistent-placeholder="true"
                  v-model="filialeToAdd.name"
                  @input="computedFooterFiliale('add')"
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
              <v-col cols="5">
                <v-autocomplete
                  placeholder="Séléctionnez"
                  :items="getTypeSociete"
                  class="select-menu"
                  label="Type de société"
                  item-text="type"
                  item-value="id"
                  :persistent-placeholder="true"
                  v-model="filialeToAdd.type_societe"
                  @input="computedFooterFiliale('add')"
                  outlined
                  dense
                  :auto-focus="false"
                  no-data-text="Aucune type société trouvée"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#704ad1"
                  item-color="#704ad1"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="2">
                <v-input-colorpicker
                  colorpick-eyedropper-active="false"
                  class="color_picker_width ml-2"
                  v-model="filialeToAdd.couleur"
                />
              </v-col>
            </v-row>
            <v-row v-if="computedCheckFilialeParticulier(filialeToAdd)">
              <v-col cols="2">
                <v-select
                  placeholder="Séléctionnez"
                  :items="genreList"
                  class="select-menu"
                  label="Civilité"
                  :persistent-placeholder="true"
                  v-model="filialeToAdd.civilite"
                  no-data-text="Aucune civilité trouvée"
                  outlined
                  dense
                  :auto-focus="false"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#704ad1"
                  item-color="#704ad1"
                >
                </v-select>
              </v-col>

              <v-col cols="3">
                <v-text-field
                  label="Prénom *"
                  mess
                  :persistent-placeholder="true"
                  v-model="filialeToAdd.prenom"
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  label="Nom de famille *"
                  mess
                  :persistent-placeholder="true"
                  v-model="filialeToAdd.nom_famille"
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
              <v-col cols="2">
                <v-text-field
                  label="Identifiant Pixel"
                  mess
                  :persistent-placeholder="true"
                  v-model="filialeToAdd.pixel_id"
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
              <v-col cols="2">
                <v-input-colorpicker
                  colorpick-eyedropper-active="false"
                  class="color_picker_width ml-2"
                  v-model="filialeToAdd.couleur"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  outlined
                  label="Adresse *"
                  v-model="filialeToAdd.adresse"
                  @input="computedFooterFiliale('add')"
                  :persistent-placeholder="true"
                  rows="3"
                  color="#704ad1"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row v-if="!computedCheckFilialeParticulier(filialeToAdd)">
              <v-col cols="3">
                <v-select
                  placeholder="Séléctionnez"
                  :items="computedDaysOfMonth(filialeToAdd.start_month)"
                  class="select-menu"
                  label="Jour début exercice *"
                  no-data-text="Aucun jour trouvé"
                  :persistent-placeholder="true"
                  v-model="filialeToAdd.start_day"
                  outlined
                  dense
                  :auto-focus="false"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#704ad1"
                  item-color="#704ad1"
                >
                </v-select>
              </v-col>
              <v-col cols="3">
                <v-select
                  placeholder="Séléctionnez"
                  :items="monthList"
                  class="select-menu"
                  label="Mois début exercice *"
                  no-data-text="Aucun mois trouvé"
                  :persistent-placeholder="true"
                  v-model="filialeToAdd.start_month"
                  outlined
                  dense
                  :auto-focus="false"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#704ad1"
                  item-color="#704ad1"
                >
                </v-select>
              </v-col>
              <v-col cols="3">
                <v-select
                  placeholder="Séléctionnez"
                  :items="computedDaysOfMonth(filialeToAdd.end_month)"
                  class="select-menu"
                  label="Jour fin exercice *"
                  :persistent-placeholder="true"
                  v-model="filialeToAdd.end_day"
                  no-data-text="Aucun jour trouvé"
                  outlined
                  dense
                  :auto-focus="false"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#704ad1"
                  item-color="#704ad1"
                >
                </v-select>
              </v-col>
              <v-col cols="3">
                <v-select
                  placeholder="Séléctionnez"
                  :items="monthList"
                  class="select-menu"
                  label="Mois fin exercice *"
                  :persistent-placeholder="true"
                  v-model="filialeToAdd.end_month"
                  no-data-text="Aucun mois trouvé"
                  outlined
                  dense
                  :auto-focus="false"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#704ad1"
                  item-color="#704ad1"
                >
                </v-select>
              </v-col>
            </v-row>
            <div class="card-style">
              <span class="title-card">Comptabilité</span>
              <v-row class="flex-row-reverse">
                <infoCompta
                  :dataToUse="filialeToAdd"
                  @validDataCompta="validDataCompta($event, 'filialeToAdd')"
                ></infoCompta>
              </v-row>
              <v-row>
                <v-col cols="3">
                  <v-text-field
                    label="Journal vente"
                    mess
                    :persistent-placeholder="true"
                    v-model="filialeToAdd.vente.journal"
                    outlined
                    color="#704ad1"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    label="Client : Compte generale "
                    mess
                    :persistent-placeholder="true"
                    v-model="filialeToAdd.customer"
                    outlined
                    color="#704ad1"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    label="Compte Prestation vente"
                    mess
                    :persistent-placeholder="true"
                    v-model="filialeToAdd.vente.prestation"
                    outlined
                    color="#704ad1"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    label="Compte Marchandise vente"
                    mess
                    :persistent-placeholder="true"
                    v-model="filialeToAdd.vente.marchandise"
                    outlined
                    color="#704ad1"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3">
                  <v-text-field
                    label="Journal achat"
                    mess
                    :persistent-placeholder="true"
                    v-model="filialeToAdd.achat.journal"
                    outlined
                    color="#704ad1"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    label="Fournisseur : Compte generale "
                    mess
                    :persistent-placeholder="true"
                    v-model="filialeToAdd.supplier"
                    outlined
                    color="#704ad1"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    label="Compte Prestation achat"
                    mess
                    :persistent-placeholder="true"
                    v-model="filialeToAdd.achat.prestation"
                    outlined
                    color="#704ad1"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    label="Compte Marchandise achat"
                    mess
                    :persistent-placeholder="true"
                    v-model="filialeToAdd.achat.marchandise"
                    outlined
                    color="#704ad1"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  <v-checkbox
                    class="checked-global checked-top"
                    v-model="filialeToAdd.is_detailed"
                    color="#704ad1"
                    :value="true"
                    label="Hors taxes Défalqué par tva"
                    :unchecked-value="0"
                    :true-value="1"
                    :false-value="0"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col cols="4">
                  <v-checkbox
                    class="checked-global checked-top"
                    v-model="filialeToAdd.is_detailed_ttc"
                    color="#704ad1"
                    :value="true"
                    label="TTC Défalqué par tva"
                    :unchecked-value="0"
                    :true-value="1"
                    :false-value="0"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col cols="4">
                  <v-checkbox
                    class="checked-global checked-top"
                    v-model="filialeToAdd.is_detailed_reste_charge"
                    color="#704ad1"
                    :value="true"
                    label="Reste à charge défalqué par tva"
                    :unchecked-value="0"
                    :true-value="1"
                    :false-value="0"
                    hide-details
                  ></v-checkbox>
                </v-col>
              </v-row>
            </div>
            <div class="card-style">
              <span class="title-card">Prime délégataire</span>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    label="Journal "
                    mess
                    :persistent-placeholder="true"
                    v-model="filialeToAdd.prime.journal"
                    outlined
                    color="#704ad1"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    label="Compte prime"
                    mess
                    :persistent-placeholder="true"
                    v-model="filialeToAdd.prime.compte"
                    outlined
                    color="#704ad1"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </div>

            <div class="card-style">
              <span class="title-card">Gestion des TVA</span>
              <v-row>
                <v-col cols="2">
                  <v-btn
                    small
                    class="btn-add-setting mr-1"
                    @click="addNewTva('add')"
                  >
                    <font-awesome-icon icon="plus" class="mr-3" /> Ajouter TVA
                  </v-btn>
                </v-col>
              </v-row>
              <v-row v-for="(tva, index) in filialeToAdd.tva" :key="index">
                <v-col cols="2">
                  <v-text-field
                    label="Tva *"
                    v-model="tva.valeur"
                    :persistent-placeholder="true"
                    required
                    outlined
                    color="#704ad1"
                    :rules="[v => !!v || 'Tva est obligatoire']"
                    class="msg-error"
                    validate-on-blur
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    label="Compte tva vente"
                    v-model="tva.compte_vente"
                    :persistent-placeholder="true"
                    required
                    outlined
                    color="#704ad1"
                    class="msg-error"
                    validate-on-blur
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    label="Compte tva achat"
                    v-model="tva.compte_achat"
                    :persistent-placeholder="true"
                    required
                    outlined
                    color="#704ad1"
                    class="msg-error"
                    validate-on-blur
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    label="compte Marchandise(HT)"
                    v-model="tva.compte_marchandise"
                    :persistent-placeholder="true"
                    required
                    outlined
                    color="#704ad1"
                    class="msg-error"
                    validate-on-blur
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    label="Compte prestation(HT)"
                    v-model="tva.compte_prestation"
                    :persistent-placeholder="true"
                    required
                    outlined
                    color="#704ad1"
                    class="msg-error"
                    validate-on-blur
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="1">
                  <v-checkbox
                    label="Activer"
                    class="checked-tva margin-none"
                    color="#704ad1"
                    :value="1"
                    :true-value="1"
                    :false-value="0"
                    hide-details
                    v-model="tva.is_active"
                  ></v-checkbox>
                </v-col>
                <v-col cols="1">
                  <font-awesome-icon
                    icon="times"
                    class="time-icon-style"
                    @click.prevent.stop="deleteTva(index, 'add')"
                  />
                </v-col>
              </v-row>
            </div>
            <v-row>
              <v-col cols="6">
                <v-select
                  placeholder="Séléctionnez"
                  :items="['text', 'image']"
                  class="select-menu"
                  label="Type de footer"
                  :persistent-placeholder="true"
                  v-model="filialeToAdd.footer_type"
                  @change="filialeToAdd.footer = null"
                  no-data-text="Aucun type trouvé"
                  outlined
                  dense
                  :auto-focus="false"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#704ad1"
                  item-color="#704ad1"
                >
                </v-select>
              </v-col>
              <v-col cols="6">
                <v-textarea
                  v-if="filialeToAdd.footer_type == 'text'"
                  outlined
                  label="Footer"
                  v-model="filialeToAdd.footer"
                  :persistent-placeholder="true"
                  rows="3"
                  color="#704ad1"
                ></v-textarea>
                <v-file-input
                  v-if="filialeToAdd.footer_type == 'image'"
                  v-model="filialeToAdd.footer"
                  counter
                  label="Footer"
                  placeholder="Aucun fichier selectionné"
                  :persistent-placeholder="true"
                  prepend-icon="mdi-paperclip"
                  outlined
                  :show-size="1000"
                  color="#704ad1"
                >
                  <template v-slot:selection="{ text }">
                    <v-chip label small>
                      {{ text }}
                    </v-chip>
                  </template>
                </v-file-input>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  :label="'Code postal' + requiredP(filialeToAdd)"
                  :persistent-placeholder="true"
                  v-model="filialeToAdd.code_postal"
                  @input="computedFooterFiliale('add')"
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  :label="'Ville' + requiredP(filialeToAdd)"
                  :persistent-placeholder="true"
                  v-model="filialeToAdd.ville"
                  @input="computedFooterFiliale('add')"
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  label="Email *"
                  :persistent-placeholder="true"
                  v-model="filialeToAdd.email"
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  :label="'Abréviation' + requiredP(filialeToAdd)"
                  :persistent-placeholder="true"
                  v-model="filialeToAdd.abreviation"
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  :label="'Siren' + requiredP(filialeToAdd)"
                  :persistent-placeholder="true"
                  v-model="filialeToAdd.siren_value"
                  @input="computedFooterFiliale('add')"
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Tva"
                  :persistent-placeholder="true"
                  v-model="filialeToAdd.tva_value"
                  @input="computedFooterFiliale('add')"
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  label="Capital"
                  :persistent-placeholder="true"
                  v-model="filialeToAdd.capital"
                  @input="computedFooterFiliale('add')"
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="RCS"
                  :persistent-placeholder="true"
                  v-model="filialeToAdd.rcs"
                  @input="computedFooterFiliale('add')"
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="6"
                v-if="!computedCheckFilialeParticulier(filialeToAdd)"
              >
                <v-text-field
                  v-if="!computedCheckFilialeParticulier(filialeToAdd)"
                  label="Site internet"
                  :persistent-placeholder="true"
                  v-model="filialeToAdd.site_internet"
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
              <v-col
                :cols="
                  !computedCheckFilialeParticulier(filialeToAdd) ? '6' : '12'
                "
              >
                <v-select
                  placeholder="Séléctionnez"
                  :items="computedListPays"
                  class="select-menu"
                  label="Pays"
                  :persistent-placeholder="true"
                  v-model="filialeToAdd.pays"
                  outlined
                  dense
                  :auto-focus="false"
                  no-data-text="Aucune pays trouvée"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#704ad1"
                  item-color="#704ad1"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                :cols="
                  !computedCheckFilialeParticulier(filialeToAdd) ? '6' : '12'
                "
              >
                <v-text-field
                  label="Siret"
                  :persistent-placeholder="true"
                  v-model="filialeToAdd.siret"
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
              <v-col
                cols="6"
                v-if="!computedCheckFilialeParticulier(filialeToAdd)"
              >
                <v-text-field
                  label="Fax"
                  :persistent-placeholder="true"
                  v-model="filialeToAdd.fax"
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-autocomplete
                  placeholder="Séléctionnez"
                  :items="computedTypeReglement"
                  class="select-menu"
                  label="Type Règlement"
                  :persistent-placeholder="true"
                  v-model="filialeToAdd.payment_type"
                  outlined
                  dense
                  :auto-focus="false"
                  no-data-text="Aucun type règlement trouvée"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#704ad1"
                  item-color="#704ad1"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="6">
                <v-autocomplete
                  placeholder="Séléctionnez"
                  :items="computedConditionPaiement"
                  class="select-menu"
                  label="Condition du paiement"
                  :persistent-placeholder="true"
                  v-model="filialeToAdd.payment_condition"
                  outlined
                  dense
                  :auto-focus="false"
                  no-data-text="Aucune condition trouvée"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#704ad1"
                  item-color="#704ad1"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                :cols="
                  computedCheckFilialeParticulier(filialeToAdd) ? '6' : '12'
                "
              >
                <v-text-field
                  label="Téléphone"
                  :persistent-placeholder="true"
                  v-model="filialeToAdd.telephone"
                  outlined
                  type="number"
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
              <v-col
                cols="6"
                v-if="computedCheckFilialeParticulier(filialeToAdd)"
              >
                <v-text-field
                  label="Téléphone portable"
                  :persistent-placeholder="true"
                  v-model="filialeToAdd.telephone_portable"
                  outlined
                  type="number"
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  outlined
                  label="Description"
                  v-model="filialeToAdd.description"
                  :persistent-placeholder="true"
                  rows="3"
                  color="#704ad1"
                ></v-textarea>
              </v-col>
            </v-row>
            <div
              class="card-style"
              v-if="
                filialeToAdd.type !== 'master filiale' &&
                  !computedCheckFilialeParticulier(filialeToAdd)
              "
            >
              <v-row>
                <v-col cols="2" v-if="computedGetLastDateInList !== null">
                  <v-btn
                    small
                    class="btn-add-setting mr-1"
                    @click="addNewMasterFiliale('add')"
                  >
                    <font-awesome-icon icon="plus" class="mr-3" /> Ajouter un
                    Master Filiale
                  </v-btn>
                </v-col>
              </v-row>
              <v-row
                v-for="(master, index) in filialeToAdd.masterFiliales"
                :key="index"
              >
                <v-col cols="4">
                  <v-select
                    placeholder="Séléctionnez"
                    :items="computedgetMasterFilialeList"
                    class="select-menu"
                    label="Nom master filiale *"
                    item-text="name"
                    item-value="master_id"
                    :persistent-placeholder="true"
                    v-model="master.master_id"
                    outlined
                    dense
                    :auto-focus="false"
                    no-data-text="Aucune Master trouvée"
                    :menu-props="{ bottom: true, offsetY: true }"
                    color="#704ad1"
                    item-color="#704ad1"
                    :disabled="index > 0"
                  >
                  </v-select>
                </v-col>
                <v-col cols="3">
                  <v-menu :close-on-content-click="true" offset-y>
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="master.date_debut"
                        label="Date début"
                        outlined
                        readonly
                        hide-details
                        v-on="on"
                        required
                        clearable
                        :persistent-placeholder="true"
                        placeholder="Sélectionner un date"
                        prepend-inner-icon="mdi-calendar"
                        :autofocus="false"
                        :disabled="index > 0"
                        color="#704ad1"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      color="#704ad1"
                      header-color="#704ad1"
                      event-color="704ad1"
                      v-model="master.date_debut"
                      :disabled="index > 0"
                      no-title
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="3">
                  <v-menu :close-on-content-click="true" offset-y>
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="master.date_fin"
                        label="Date fin"
                        outlined
                        readonly
                        hide-details
                        v-on="on"
                        required
                        clearable
                        :persistent-placeholder="true"
                        placeholder="Sélectionner un date"
                        prepend-inner-icon="mdi-calendar"
                        :autofocus="false"
                        color="#704ad1"
                        :disabled="index > 0"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      color="#704ad1"
                      header-color="#704ad1"
                      event-color="704ad1"
                      v-model="master.date_fin"
                      :disabled="index > 0"
                      no-title
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="1">
                  <font-awesome-icon
                    icon="times"
                    class="time-icon-style"
                    @click="deleteMasterFiliale(index, 'add')"
                  />
                </v-col>
              </v-row>
            </div>
            <v-row v-if="!computedCheckFilialeParticulier(filialeToAdd)">
              <v-col cols="12">
                <v-autocomplete
                  color="#704ad1"
                  item-color="#704ad1"
                  v-model="filialeToAdd.responsables"
                  :items="computedChangeResponsable"
                  placeholder="Séléctionnez"
                  :persistent-placeholder="true"
                  label="Support(s)"
                  multiple
                  clearable
                  item-text="full_name"
                  item-value="id"
                  :auto-focus="false"
                  no-data-text="Aucune responsable trouvé."
                  outlined
                >
                  <template v-slot:selection="data">
                    <v-chip
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      close
                      @click="data.select"
                      @click:close="remove(data.item, 'add')"
                    >
                      {{ data.item.name }}
                    </v-chip>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <div
              class="card-style"
              v-if="!computedCheckFilialeParticulier(filialeToAdd)"
            >
              <v-row>
                <v-col cols="12">
                  <v-file-input
                    v-model="filialeToAdd.image"
                    counter
                    label="Logo Filiale"
                    placeholder="Aucun Logo selectionné"
                    :persistent-placeholder="true"
                    prepend-icon="mdi-paperclip"
                    outlined
                    :show-size="1000"
                    color="#704ad1"
                  >
                    <template v-slot:selection="{ text }">
                      <v-chip label small>
                        {{ text }}
                      </v-chip>
                    </template>
                  </v-file-input>
                </v-col>
              </v-row>
            </div>

            <v-row v-if="!computedCheckFilialeParticulier(filialeToAdd)">
              <v-col cols="3">
                <v-checkbox
                  class="checked-global checked-top"
                  v-model="filialeToAdd.volant"
                  color="#704ad1"
                  :value="true"
                  label="Volant"
                  :unchecked-value="false"
                  @change="checkVolant"
                  :true-value="true"
                  :false-value="false"
                  hide-details
                ></v-checkbox>
              </v-col>
              <v-col cols="6" v-if="filialeToAdd.volant == true">
                <v-text-field
                  label="Responsable de dépôt"
                  :persistent-placeholder="true"
                  v-model="filialeToAdd.responsable_depot"
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row
              v-if="
                filialeToAdd.volant == false &&
                  !computedCheckFilialeParticulier(filialeToAdd)
              "
            >
              <v-col cols="12">
                <v-autocomplete
                  color="#704ad1"
                  item-color="#704ad1"
                  v-model="filialeToAdd.depots"
                  :items="getDepotsTh"
                  placeholder="Séléctionnez"
                  :persistent-placeholder="true"
                  label="Dépôt"
                  multiple
                  clearable
                  return-object
                  item-text="depot"
                  item-value="id"
                  :auto-focus="false"
                  no-data-text="Aucune dépôt trouvé."
                  outlined
                >
                  <template v-slot:selection="data">
                    <v-chip
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      close
                      @click="data.select"
                      @click:close="removeDepot(data.item, 'add')"
                    >
                      {{ data.item.depot }}
                    </v-chip>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row
              v-show="
                filialeToAdd.volant == false &&
                  !computedCheckFilialeParticulier(filialeToAdd)
              "
              v-for="depot in filialeToAdd.depots"
              :key="'depot' + depot.id"
            >
              <v-col cols="2">
                <p class="ml-10">
                  {{ depot.depot }}
                </p>
              </v-col>
              <v-col cols="10">
                <v-text-field
                  label="Responsable de dépôt"
                  :persistent-placeholder="true"
                  v-model="depot.responsable_depot"
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <div
              class="card-style"
              v-if="
                filialeToAdd.volant == false &&
                  !computedCheckFilialeParticulier(filialeToAdd)
              "
            >
              <v-row
                v-if="
                  filialeToAdd.volant == false &&
                    !computedCheckFilialeParticulier(filialeToAdd)
                "
              >
                <v-col cols="12">
                  <v-file-input
                    v-model="filialeToAdd.files"
                    counter
                    label="Fichiers"
                    multiple
                    placeholder="Aucun fichier selectionné"
                    :persistent-placeholder="true"
                    prepend-icon="mdi-paperclip"
                    outlined
                    :show-size="1000"
                    color="#704ad1"
                  >
                    <template v-slot:selection="{ text }">
                      <v-chip label small>
                        {{ text }}
                      </v-chip>
                    </template>
                  </v-file-input>
                </v-col>
              </v-row>
              <div v-if="filialeToAdd.files && filialeToAdd.files.length > 0">
                <v-row
                  v-for="(file, index) in filialeToAdd.files"
                  :key="'file' + index"
                >
                  <v-col cols="4">
                    <font-awesome-icon
                      icon="paperclip"
                      class="file-upload-icon mr-1"
                    />
                    {{ file.name }}</v-col
                  >
                  <v-col cols="8">
                    <v-text-field
                      outlined
                      label="Nom du fichier"
                      v-model="file.name_file"
                      :persistent-placeholder="true"
                      required
                      color="#704ad1"
                    ></v-text-field
                  ></v-col>
                </v-row>
              </div>
            </div>
          </form>
          <div v-if="error" class="message-error-modal">
            <ul v-if="Array.isArray(error)" class="mb-0">
              <li v-for="(e, index) in error" :key="index">
                {{ e }}
              </li>
            </ul>
            <div class="message-error-modal" v-else>{{ error }}</div>
          </div>
        </v-card-text>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            text
            type="submit"
            @click.prevent="handleSubmitFiliale"
            :loading="isLoading"
            :disabled="isLoading"
            :class="{ loader: isLoading }"
          >
            Valider
          </v-btn>
          <v-btn text @click="handleModalFiliale('add', 'hide')">
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--MODAL UPDATE FILIALE-->
    <v-dialog
      v-model="updateModalFiliale"
      persistent
      max-width="1500px"
      :scrollable="true"
      :hide-overlay="true"
      no-click-animation
      content-class="v-dialog.custom-vuetify-dialog-list"
    >
      <v-card v-if="filialeToUpdate && filialeToUpdate != null">
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">Modifier une société </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="handleModalFiliale('update', 'hide')"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr"></v-divider>
        <v-card-text class="body-card">
          <form class="form-add">
            <v-row
              v-if="
                !computedCheckFilialeParticulier(filialeToUpdate) &&
                  filialeToUpdate.logo
              "
            >
              <v-col cols="6">
                <div>
                  <img
                    class="image-preview-inside"
                    :src="filialeToUpdate.logo"
                  />
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3">
                <v-select
                  placeholder="Séléctionnez"
                  :items="options"
                  class="select-menu"
                  label="Type *"
                  :persistent-placeholder="true"
                  v-model="filialeToUpdate.type"
                  outlined
                  dense
                  :auto-focus="false"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#704ad1"
                  item-color="#704ad1"
                  :disabled="filialeToUpdate.type === 'master filiale'"
                >
                </v-select>
              </v-col>
              <v-col
                cols="2"
                v-if="!computedCheckFilialeParticulier(filialeToUpdate)"
              >
                <v-checkbox
                  class="checked-global checked-top"
                  v-model="filialeToUpdate.in_configs"
                  color="#704ad1"
                  :value="'oui'"
                  label="Configure une société"
                  :unchecked-value="'non'"
                  hide-details
                ></v-checkbox>
              </v-col>
              <v-col
                cols="2"
                v-if="!computedCheckFilialeParticulier(filialeToUpdate)"
              >
                <v-checkbox
                  class="checked-global checked-top"
                  v-model="filialeToUpdate.is_societe_support"
                  @change="onChangeSupport('update')"
                  color="#704ad1"
                  :value="true"
                  :unchecked-value="false"
                  label="Support"
                  hide-details
                ></v-checkbox>
              </v-col>
              <v-col
                cols="3"
                v-if="
                  filialeToUpdate.is_societe_support == true &&
                    !computedCheckFilialeParticulier(filialeToUpdate)
                "
              >
                <v-select
                  placeholder="Séléctionnez"
                  :items="getTypeSocieteSupportTh"
                  class="select-menu"
                  label="Type *"
                  :persistent-placeholder="true"
                  v-model="filialeToUpdate.type_societe_support"
                  outlined
                  dense
                  :auto-focus="false"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#704ad1"
                  item-color="#704ad1"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row v-if="!computedCheckFilialeParticulier(filialeToUpdate)">
              <v-col cols="5">
                <v-text-field
                  label="Nom de société *"
                  mess
                  :persistent-placeholder="true"
                  v-model="filialeToUpdate.name"
                  @input="computedFooterFiliale('update')"
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
              <v-col cols="5">
                <v-autocomplete
                  placeholder="Séléctionnez"
                  :items="getTypeSociete"
                  class="select-menu"
                  label="Type de société"
                  item-text="type"
                  item-value="id"
                  :persistent-placeholder="true"
                  v-model="filialeToUpdate.type_societe"
                  @input="computedFooterFiliale('update')"
                  outlined
                  dense
                  :auto-focus="false"
                  no-data-text="Aucune type société trouvée"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#704ad1"
                  item-color="#704ad1"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="2">
                <v-input-colorpicker
                  colorpick-eyedropper-active="false"
                  class="color_picker_width ml-2"
                  v-model="filialeToUpdate.couleur"
                />
              </v-col>
            </v-row>
            <v-row v-if="computedCheckFilialeParticulier(filialeToUpdate)">
              <v-col cols="2">
                <v-select
                  placeholder="Séléctionnez"
                  :items="genreList"
                  class="select-menu"
                  label="Civilité"
                  :persistent-placeholder="true"
                  v-model="filialeToUpdate.civilite"
                  no-data-text="Aucune civilité trouvée"
                  outlined
                  dense
                  :auto-focus="false"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#704ad1"
                  item-color="#704ad1"
                >
                </v-select>
              </v-col>

              <v-col cols="3">
                <v-text-field
                  label="Prénom *"
                  mess
                  :persistent-placeholder="true"
                  v-model="filialeToUpdate.prenom"
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  label="Nom de famille *"
                  mess
                  :persistent-placeholder="true"
                  v-model="filialeToUpdate.nom_famille"
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
              <v-col cols="2">
                <v-text-field
                  label="Identifiant Pixel"
                  mess
                  :persistent-placeholder="true"
                  v-model="filialeToUpdate.pixel_id"
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
              <v-col cols="2">
                <v-input-colorpicker
                  colorpick-eyedropper-active="false"
                  class="color_picker_width ml-2"
                  v-model="filialeToUpdate.couleur"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  outlined
                  label="Adresse *"
                  v-model="filialeToUpdate.adresse"
                  @input="computedFooterFiliale('update')"
                  :persistent-placeholder="true"
                  rows="3"
                  color="#704ad1"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row v-if="!computedCheckFilialeParticulier(filialeToUpdate)">
              <v-col cols="3">
                <v-select
                  placeholder="Séléctionnez"
                  :items="computedDaysOfMonth(filialeToUpdate.start_month)"
                  class="select-menu"
                  label="Jour début exercice *"
                  no-data-text="Aucun jour trouvé"
                  :persistent-placeholder="true"
                  v-model="filialeToUpdate.start_day"
                  outlined
                  dense
                  :auto-focus="false"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#704ad1"
                  item-color="#704ad1"
                >
                </v-select>
              </v-col>
              <v-col cols="3">
                <v-select
                  placeholder="Séléctionnez"
                  :items="monthList"
                  class="select-menu"
                  label="Mois début exercice *"
                  no-data-text="Aucun mois trouvé"
                  :persistent-placeholder="true"
                  v-model="filialeToUpdate.start_month"
                  outlined
                  dense
                  :auto-focus="false"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#704ad1"
                  item-color="#704ad1"
                >
                </v-select>
              </v-col>
              <v-col cols="3">
                <v-select
                  placeholder="Séléctionnez"
                  :items="computedDaysOfMonth(filialeToUpdate.end_month)"
                  class="select-menu"
                  label="Jour fin exercice *"
                  :persistent-placeholder="true"
                  v-model="filialeToUpdate.end_day"
                  no-data-text="Aucun jour trouvé"
                  outlined
                  dense
                  :auto-focus="false"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#704ad1"
                  item-color="#704ad1"
                >
                </v-select>
              </v-col>
              <v-col cols="3">
                <v-select
                  placeholder="Séléctionnez"
                  :items="monthList"
                  class="select-menu"
                  label="Mois fin exercice *"
                  :persistent-placeholder="true"
                  v-model="filialeToUpdate.end_month"
                  no-data-text="Aucun mois trouvé"
                  outlined
                  dense
                  :auto-focus="false"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#704ad1"
                  item-color="#704ad1"
                >
                </v-select>
              </v-col>
            </v-row>
            <div class="card-style">
              <span class="title-card">Comptabilité</span>
              <v-row class="flex-row-reverse">
                <planTiers
                  v-if="checkPermission('GSGDPT')"
                  :item="filialeToUpdate"
                ></planTiers>

                <infoCompta
                  :dataToUse="filialeToUpdate"
                  @validDataCompta="validDataCompta($event, 'filialeToUpdate')"
                ></infoCompta>
              </v-row>
              <v-row>
                <v-col cols="3">
                  <v-text-field
                    label="Journal vente"
                    mess
                    :persistent-placeholder="true"
                    v-model="filialeToUpdate.vente.journal"
                    outlined
                    color="#704ad1"
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="3">
                  <v-text-field
                    label="Client : Compte generale "
                    mess
                    :persistent-placeholder="true"
                    v-model="filialeToUpdate.customer"
                    outlined
                    color="#704ad1"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    label="Compte Prestation vente"
                    mess
                    :persistent-placeholder="true"
                    v-model="filialeToUpdate.vente.prestation"
                    outlined
                    color="#704ad1"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    label="Compte Marchandise vente"
                    mess
                    :persistent-placeholder="true"
                    v-model="filialeToUpdate.vente.marchandise"
                    outlined
                    color="#704ad1"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3">
                  <v-text-field
                    label="Journal achat"
                    mess
                    :persistent-placeholder="true"
                    v-model="filialeToUpdate.achat.journal"
                    outlined
                    color="#704ad1"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    label="Fournisseur : Compte generale "
                    mess
                    :persistent-placeholder="true"
                    v-model="filialeToUpdate.supplier"
                    outlined
                    color="#704ad1"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    label="Compte Prestation achat"
                    mess
                    :persistent-placeholder="true"
                    v-model="filialeToUpdate.achat.prestation"
                    outlined
                    color="#704ad1"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    label="Compte Marchandise achat"
                    mess
                    :persistent-placeholder="true"
                    v-model="filialeToUpdate.achat.marchandise"
                    outlined
                    color="#704ad1"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  <v-checkbox
                    class="checked-global checked-top"
                    v-model="filialeToUpdate.is_detailed"
                    color="#704ad1"
                    :value="true"
                    label="Hors taxes Défalqué par tva"
                    :unchecked-value="0"
                    :true-value="1"
                    :false-value="0"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col cols="4">
                  <v-checkbox
                    class="checked-global checked-top"
                    v-model="filialeToUpdate.is_detailed_ttc"
                    color="#704ad1"
                    :value="true"
                    label="TTC Défalqué par tva"
                    :unchecked-value="0"
                    :true-value="1"
                    :false-value="0"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col cols="4">
                  <v-checkbox
                    class="checked-global checked-top"
                    v-model="filialeToUpdate.is_detailed_reste_charge"
                    color="#704ad1"
                    :value="true"
                    label="Reste à charge défalqué par tva"
                    :unchecked-value="0"
                    :true-value="1"
                    :false-value="0"
                    hide-details
                  ></v-checkbox>
                </v-col>
              </v-row>
            </div>
            <div class="card-style">
              <span class="title-card">Prime délégataire</span>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    label="Journal "
                    mess
                    :persistent-placeholder="true"
                    v-model="filialeToUpdate.prime.journal"
                    outlined
                    color="#704ad1"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    label="Compte prime"
                    mess
                    :persistent-placeholder="true"
                    v-model="filialeToUpdate.prime.compte"
                    outlined
                    color="#704ad1"
                  >
                  </v-text-field>
                </v-col>
                <!-- <v-col cols="4">
                  <v-text-field
                    label="Compte tiers"
                    mess
                    :persistent-placeholder="true"
                    v-model="filialeToUpdate.prime.tiers"
                    outlined
                    color="#704ad1"
                  >
                  </v-text-field>
                </v-col> -->
              </v-row>
            </div>
            <div class="card-style">
              <span class="title-card">Gestion des TVA</span>
              <v-row>
                <v-col cols="2">
                  <v-btn
                    small
                    class="btn-add-setting mr-1"
                    @click="addNewTva('update')"
                  >
                    <font-awesome-icon icon="plus" class="mr-3" /> Ajouter TVA
                  </v-btn>
                </v-col>
              </v-row>
              <v-row v-for="(tva, index) in filialeToUpdate.tva" :key="index">
                <v-col cols="2">
                  <v-text-field
                    label="Tva *"
                    v-model="tva.valeur"
                    :persistent-placeholder="true"
                    required
                    outlined
                    color="#704ad1"
                    :rules="[v => !!v || 'Tva est obligatoire']"
                    class="msg-error"
                    validate-on-blur
                    :disabled="tva.id ? true : false"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    label="Compte tva vente"
                    v-model="tva.compte_vente"
                    :persistent-placeholder="true"
                    required
                    outlined
                    color="#704ad1"
                    class="msg-error"
                    validate-on-blur
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    label="Compte tva achat"
                    v-model="tva.compte_achat"
                    :persistent-placeholder="true"
                    required
                    outlined
                    color="#704ad1"
                    class="msg-error"
                    validate-on-blur
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    label="compte Marchandise(HT)"
                    v-model="tva.compte_marchandise"
                    :persistent-placeholder="true"
                    required
                    outlined
                    color="#704ad1"
                    class="msg-error"
                    validate-on-blur
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    label="Compte prestation(HT)"
                    v-model="tva.compte_prestation"
                    :persistent-placeholder="true"
                    required
                    outlined
                    color="#704ad1"
                    class="msg-error"
                    validate-on-blur
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="1">
                  <v-checkbox
                    label="Activer"
                    class="checked-tva margin-none"
                    color="#704ad1"
                    :value="1"
                    :true-value="1"
                    :false-value="0"
                    hide-details
                    v-model="tva.is_active"
                  ></v-checkbox>
                </v-col>
                <v-col cols="1">
                  <font-awesome-icon
                    icon="times"
                    class="time-icon-style"
                    @click.prevent.stop="deleteTva(index, 'update')"
                  />
                </v-col>
              </v-row>
            </div>

            <v-row>
              <v-col cols="6">
                <v-select
                  placeholder="Séléctionnez"
                  :items="['text', 'image']"
                  class="select-menu"
                  label="Type de footer"
                  :persistent-placeholder="true"
                  v-model="filialeToUpdate.footer_type"
                  @change="filialeToUpdate.footer = null"
                  no-data-text="Aucun type trouvé"
                  outlined
                  dense
                  :auto-focus="false"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#704ad1"
                  item-color="#704ad1"
                >
                </v-select>
              </v-col>
              <v-col cols="6">
                <v-textarea
                  v-if="filialeToUpdate.footer_type == 'text'"
                  outlined
                  label="Footer"
                  v-model="filialeToUpdate.footer"
                  :persistent-placeholder="true"
                  rows="3"
                  color="#704ad1"
                ></v-textarea>
                <v-file-input
                  v-if="filialeToUpdate.footer_type == 'image'"
                  v-model="filialeToUpdate.footer"
                  counter
                  label="Footer"
                  placeholder="Aucun fichier selectionné"
                  :persistent-placeholder="true"
                  prepend-icon="mdi-paperclip"
                  outlined
                  :show-size="1000"
                  color="#704ad1"
                >
                  <template v-slot:selection="{ text }">
                    <v-chip label small>
                      {{ text }}
                    </v-chip>
                  </template>
                </v-file-input>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  :label="'Code postal' + requiredP(filialeToUpdate)"
                  :persistent-placeholder="true"
                  v-model="filialeToUpdate.code_postal"
                  @input="computedFooterFiliale('update')"
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  :label="'Ville' + requiredP(filialeToUpdate)"
                  :persistent-placeholder="true"
                  v-model="filialeToUpdate.ville"
                  @input="computedFooterFiliale('update')"
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  label="Email *"
                  :persistent-placeholder="true"
                  v-model="filialeToUpdate.email"
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  :label="'Abréviation' + requiredP(filialeToUpdate)"
                  :persistent-placeholder="true"
                  v-model="filialeToUpdate.abreviation"
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  :label="'Siren' + requiredP(filialeToUpdate)"
                  :persistent-placeholder="true"
                  v-model="filialeToUpdate.siren_value"
                  @input="computedFooterFiliale('update')"
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Tva"
                  :persistent-placeholder="true"
                  v-model="filialeToUpdate.tva_value"
                  @input="computedFooterFiliale('update')"
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  label="Capital"
                  :persistent-placeholder="true"
                  v-model="filialeToUpdate.capital"
                  @input="computedFooterFiliale('update')"
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="RCS"
                  :persistent-placeholder="true"
                  v-model="filialeToUpdate.rcs"
                  @input="computedFooterFiliale('update')"
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="6"
                v-if="!computedCheckFilialeParticulier(filialeToUpdate)"
              >
                <v-text-field
                  v-if="!computedCheckFilialeParticulier(filialeToUpdate)"
                  label="Site internet"
                  :persistent-placeholder="true"
                  v-model="filialeToUpdate.site_internet"
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
              <v-col
                :cols="
                  !computedCheckFilialeParticulier(filialeToUpdate) ? '6' : '12'
                "
              >
                <v-select
                  placeholder="Séléctionnez"
                  :items="computedListPays"
                  class="select-menu"
                  label="Pays"
                  :persistent-placeholder="true"
                  v-model="filialeToUpdate.pays"
                  outlined
                  dense
                  :auto-focus="false"
                  no-data-text="Aucune pays trouvée"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#704ad1"
                  item-color="#704ad1"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                :cols="
                  !computedCheckFilialeParticulier(filialeToUpdate) ? '6' : '12'
                "
              >
                <v-text-field
                  label="Siret"
                  :persistent-placeholder="true"
                  v-model="filialeToUpdate.siret"
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
              <v-col
                cols="6"
                v-if="!computedCheckFilialeParticulier(filialeToUpdate)"
              >
                <v-text-field
                  label="Fax"
                  :persistent-placeholder="true"
                  v-model="filialeToUpdate.fax"
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-autocomplete
                  placeholder="Séléctionnez"
                  :items="computedTypeReglement"
                  class="select-menu"
                  label="Type Règlement"
                  :persistent-placeholder="true"
                  v-model="filialeToUpdate.payment_type"
                  outlined
                  dense
                  :auto-focus="false"
                  no-data-text="Aucun type règlement trouvée"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#704ad1"
                  item-color="#704ad1"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="6">
                <v-autocomplete
                  placeholder="Séléctionnez"
                  :items="computedConditionPaiement"
                  class="select-menu"
                  label="Condition du paiement"
                  :persistent-placeholder="true"
                  v-model="filialeToUpdate.payment_condition"
                  outlined
                  dense
                  :auto-focus="false"
                  no-data-text="Aucune condition trouvée"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#704ad1"
                  item-color="#704ad1"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                :cols="
                  computedCheckFilialeParticulier(filialeToUpdate) ? '6' : '12'
                "
              >
                <v-text-field
                  label="Téléphone"
                  :persistent-placeholder="true"
                  v-model="filialeToUpdate.telephone"
                  outlined
                  type="number"
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
              <v-col
                cols="6"
                v-if="computedCheckFilialeParticulier(filialeToUpdate)"
              >
                <v-text-field
                  label="Téléphone portable"
                  :persistent-placeholder="true"
                  v-model="filialeToUpdate.telephone_portable"
                  outlined
                  type="number"
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  outlined
                  label="Description"
                  v-model="filialeToUpdate.description"
                  :persistent-placeholder="true"
                  rows="3"
                  color="#704ad1"
                ></v-textarea>
              </v-col>
            </v-row>
            <div
              class="card-style"
              v-if="
                filialeToUpdate.type !== 'master filiale' &&
                  !computedCheckFilialeParticulier(filialeToUpdate)
              "
            >
              <v-row>
                <v-col cols="2" v-if="computedGetLastDateInList !== null">
                  <v-btn
                    small
                    class="btn-add-setting mr-1"
                    @click="addNewMasterFiliale('update')"
                  >
                    <font-awesome-icon icon="plus" class="mr-3" /> Ajouter un
                    Master Filiale
                  </v-btn>
                </v-col>
              </v-row>
              <v-row
                v-for="(master, index) in filialeToUpdate.masterFiliales"
                :key="index"
              >
                <v-col cols="4">
                  <v-select
                    placeholder="Séléctionnez"
                    :items="computedgetMasterFilialeList"
                    class="select-menu"
                    label="Nom master filiale *"
                    item-text="name"
                    item-value="master_id"
                    :persistent-placeholder="true"
                    v-model="master.master_id"
                    outlined
                    dense
                    :auto-focus="false"
                    no-data-text="Aucune Master trouvée"
                    :menu-props="{ bottom: true, offsetY: true }"
                    color="#704ad1"
                    item-color="#704ad1"
                    :disabled="index > 0"
                  >
                  </v-select>
                </v-col>
                <v-col cols="3">
                  <v-menu :close-on-content-click="true" offset-y>
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="master.date_debut"
                        label="Date début"
                        outlined
                        readonly
                        hide-details
                        v-on="on"
                        required
                        clearable
                        :persistent-placeholder="true"
                        placeholder="Sélectionner un date"
                        prepend-inner-icon="mdi-calendar"
                        :autofocus="false"
                        color="#704ad1"
                        :disabled="index > 0"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      color="#704ad1"
                      header-color="#704ad1"
                      event-color="704ad1"
                      v-model="master.date_debut"
                      no-title
                      :disabled="index > 0"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="3">
                  <v-menu :close-on-content-click="true" offset-y>
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="master.date_fin"
                        label="Date fin"
                        outlined
                        readonly
                        hide-details
                        v-on="on"
                        required
                        clearable
                        :persistent-placeholder="true"
                        placeholder="Sélectionner un date"
                        prepend-inner-icon="mdi-calendar"
                        :autofocus="false"
                        color="#704ad1"
                        :disabled="index > 0"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      color="#704ad1"
                      header-color="#704ad1"
                      event-color="704ad1"
                      v-model="master.date_fin"
                      :disabled="index > 0"
                      no-title
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="1">
                  <font-awesome-icon
                    icon="times"
                    class="time-icon-style"
                    @click="deleteMasterFiliale(index, 'add')"
                  />
                </v-col>
              </v-row>
            </div>
            <v-row v-if="!computedCheckFilialeParticulier(filialeToUpdate)">
              <v-col cols="12">
                <v-autocomplete
                  color="#704ad1"
                  item-color="#704ad1"
                  v-model="filialeToUpdate.responsables"
                  :items="computedChangeResponsable"
                  placeholder="Séléctionnez"
                  :persistent-placeholder="true"
                  label="Support(s)"
                  multiple
                  clearable
                  item-text="full_name"
                  item-value="id"
                  :auto-focus="false"
                  no-data-text="Aucune responsable trouvé."
                  outlined
                >
                  <template v-slot:selection="data">
                    <v-chip
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      close
                      @click="data.select"
                      @click:close="remove(data.item, 'update')"
                    >
                      {{ data.item.name }}
                    </v-chip>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <div
              class="card-style"
              v-if="!computedCheckFilialeParticulier(filialeToUpdate)"
            >
              <v-row>
                <v-col cols="12">
                  <v-file-input
                    v-model="filialeToUpdate.image"
                    counter
                    label="Logo Filiale"
                    placeholder="Aucun Logo selectionné"
                    :persistent-placeholder="true"
                    prepend-icon="mdi-paperclip"
                    outlined
                    :show-size="1000"
                    color="#704ad1"
                  >
                    <template v-slot:selection="{ text }">
                      <v-chip label small>
                        {{ text }}
                      </v-chip>
                    </template>
                  </v-file-input>
                </v-col>
              </v-row>
            </div>

            <v-row v-if="!computedCheckFilialeParticulier(filialeToUpdate)">
              <v-col cols="3">
                <v-checkbox
                  class="checked-global checked-top"
                  v-model="filialeToUpdate.volant"
                  color="#704ad1"
                  :value="true"
                  label="Volant"
                  :unchecked-value="false"
                  @change="checkVolant"
                  :true-value="true"
                  :false-value="false"
                  hide-details
                ></v-checkbox>
              </v-col>
              <v-col cols="6" v-if="filialeToUpdate.volant == true">
                <v-text-field
                  label="Responsable de dépôt"
                  :persistent-placeholder="true"
                  v-model="filialeToUpdate.responsable_depot"
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row
              v-if="
                filialeToUpdate.volant == false &&
                  !computedCheckFilialeParticulier(filialeToUpdate)
              "
            >
              <v-col cols="12">
                <v-autocomplete
                  color="#704ad1"
                  item-color="#704ad1"
                  v-model="filialeToUpdate.depots"
                  :items="getDepotsTh"
                  placeholder="Séléctionnez"
                  :persistent-placeholder="true"
                  label="Dépôt"
                  multiple
                  clearable
                  return-object
                  item-text="depot"
                  item-value="id"
                  :auto-focus="false"
                  no-data-text="Aucune dépôt trouvé."
                  outlined
                >
                  <template v-slot:selection="data">
                    <v-chip
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      close
                      @click="data.select"
                      @click:close="removeDepot(data.item, 'update')"
                    >
                      {{ data.item.depot }}
                    </v-chip>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row
              v-show="
                filialeToUpdate.volant == false &&
                  !computedCheckFilialeParticulier(filialeToUpdate)
              "
              v-for="depot in filialeToUpdate.depots"
              :key="'depot' + depot.id"
            >
              <v-col cols="2">
                <p class="ml-10">
                  {{ depot.depot }}
                </p>
              </v-col>
              <v-col cols="10">
                <v-text-field
                  label="Responsable de dépôt"
                  :persistent-placeholder="true"
                  v-model="depot.responsable_depot"
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <div
              class="card-style"
              v-if="
                filialeToUpdate.volant == false &&
                  !computedCheckFilialeParticulier(filialeToUpdate)
              "
            >
              <v-row
                v-if="
                  filialeToUpdate.volant == false &&
                    !computedCheckFilialeParticulier(filialeToUpdate)
                "
              >
                <v-col cols="12">
                  <v-file-input
                    v-model="newFiles"
                    counter
                    label="Fichiers"
                    multiple
                    placeholder="Aucun fichier selectionné"
                    :persistent-placeholder="true"
                    prepend-icon="mdi-paperclip"
                    outlined
                    :show-size="1000"
                    color="#704ad1"
                  >
                    <template v-slot:selection="{ text }">
                      <v-chip label small>
                        {{ text }}
                      </v-chip>
                    </template>
                  </v-file-input>
                </v-col>
              </v-row>
              <div
                v-if="filialeToUpdate.files && filialeToUpdate.files.length > 0"
              >
                <v-row
                  v-for="(file, index) in filialeToUpdate.files"
                  :key="'file' + index"
                >
                  <v-col cols="4">
                    <font-awesome-icon
                      icon="paperclip"
                      class="file-upload-icon mr-1"
                    />
                    <a
                      :href="file.link"
                      target="_blank"
                      v-if="file.hasOwnProperty('link')"
                    >
                      {{ file.name }}
                    </a>
                    <p v-else>
                      {{ file.name }}
                    </p>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      outlined
                      label="Nom du fichier"
                      v-model="file.name"
                      :persistent-placeholder="true"
                      @input="handleRenameFile(file, filialeToUpdate, index)"
                      required
                      color="#704ad1"
                    ></v-text-field
                  ></v-col>
                  <v-col cols="2">
                    <div
                      @click.prevent.stop="deleteFile(file, index)"
                      title="Supprimer fichier"
                      class="delete-file ml-2"
                    >
                      <font-awesome-icon icon="trash" />
                    </div>
                  </v-col>
                </v-row>
              </div>
              <div v-if="newFiles && newFiles.length > 0">
                <v-row v-for="(file, index) in newFiles" :key="'file' + index">
                  <v-col cols="4">
                    <font-awesome-icon
                      icon="paperclip"
                      class="file-upload-icon mr-1"
                    />
                    {{ file.name }}</v-col
                  >
                  <v-col cols="6">
                    <v-text-field
                      outlined
                      label="Nom du fichier"
                      v-model="file.name_file"
                      :persistent-placeholder="true"
                      required
                      color="#704ad1"
                    ></v-text-field
                  ></v-col>
                  <v-col cols="2">
                    <div
                      @click.prevent.stop="deleteFile(file, index)"
                      title="Supprimer fichier"
                      class="ml-2"
                    >
                      <font-awesome-icon icon="trash" />
                    </div>
                  </v-col>
                </v-row>
              </div>
            </div>
          </form>
          <div v-if="error" class="message-error-modal">
            <ul v-if="Array.isArray(error)" class="mb-0">
              <li v-for="(e, index) in error" :key="index">
                {{ e }}
              </li>
            </ul>
            <div class="message-error-modal" v-else>{{ error }}</div>
          </div>
        </v-card-text>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            text
            @click.prevent="handleSubmitUpdateFiliale"
            :loading="isLoading"
            :disabled="isLoading"
            :class="{ loader: isLoading }"
          >
            Valider
          </v-btn>
          <v-btn text @click="handleModalFiliale('update', 'hide')">
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--MODAL DUPLICATE FILIALE-->
    <v-dialog
      v-model="duplicateModalFiliale"
      persistent
      max-width="1500px"
      :scrollable="true"
      :hide-overlay="true"
      no-click-animation
      content-class="v-dialog.custom-vuetify-dialog-list"
    >
      <v-card v-if="filialeToUpdate && filialeToUpdate != null">
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">Dupliquer une société </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="handleModalFiliale('duplicate', 'hide')"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr"></v-divider>
        <v-card-text class="body-card">
          <form class="form-add">
            <v-row
              v-if="
                !computedCheckFilialeParticulier(filialeToUpdate) &&
                  filialeToUpdate.logo
              "
            >
              <v-col cols="6">
                <div>
                  <img
                    class="image-preview-inside"
                    :src="filialeToUpdate.logo"
                  />
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3">
                <v-select
                  placeholder="Séléctionnez"
                  :items="options"
                  class="select-menu"
                  label="Type *"
                  :persistent-placeholder="true"
                  v-model="filialeToUpdate.type"
                  outlined
                  dense
                  :auto-focus="false"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#704ad1"
                  item-color="#704ad1"
                  :disabled="filialeToUpdate.type === 'master filiale'"
                >
                </v-select>
              </v-col>
              <v-col
                cols="2"
                v-if="!computedCheckFilialeParticulier(filialeToUpdate)"
              >
                <v-checkbox
                  class="checked-global checked-top"
                  v-model="filialeToUpdate.in_configs"
                  color="#704ad1"
                  :value="'oui'"
                  label="Configure une société"
                  :unchecked-value="'non'"
                  hide-details
                ></v-checkbox>
              </v-col>
              <v-col
                cols="2"
                v-if="!computedCheckFilialeParticulier(filialeToUpdate)"
              >
                <v-checkbox
                  class="checked-global checked-top"
                  v-model="filialeToUpdate.is_societe_support"
                  @change="onChangeSupport('update')"
                  color="#704ad1"
                  :value="true"
                  :unchecked-value="false"
                  label="Support"
                  hide-details
                ></v-checkbox>
              </v-col>
              <v-col
                cols="3"
                v-if="
                  filialeToUpdate.is_societe_support == true &&
                    !computedCheckFilialeParticulier(filialeToUpdate)
                "
              >
                <v-select
                  placeholder="Séléctionnez"
                  :items="getTypeSocieteSupportTh"
                  class="select-menu"
                  label="Type *"
                  :persistent-placeholder="true"
                  v-model="filialeToUpdate.type_societe_support"
                  outlined
                  dense
                  :auto-focus="false"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#704ad1"
                  item-color="#704ad1"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row v-if="!computedCheckFilialeParticulier(filialeToUpdate)">
              <v-col cols="5">
                <v-text-field
                  label="Nom de société *"
                  mess
                  :persistent-placeholder="true"
                  v-model="filialeToUpdate.name"
                  @input="computedFooterFiliale('update')"
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
              <v-col cols="5">
                <v-autocomplete
                  placeholder="Séléctionnez"
                  :items="getTypeSociete"
                  class="select-menu"
                  label="Type de société"
                  item-text="type"
                  item-value="id"
                  :persistent-placeholder="true"
                  v-model="filialeToUpdate.type_societe"
                  @input="computedFooterFiliale('update')"
                  outlined
                  dense
                  :auto-focus="false"
                  no-data-text="Aucune type société trouvée"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#704ad1"
                  item-color="#704ad1"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="2">
                <v-input-colorpicker
                  colorpick-eyedropper-active="false"
                  class="color_picker_width ml-2"
                  v-model="filialeToUpdate.couleur"
                />
              </v-col>
            </v-row>
            <v-row v-if="computedCheckFilialeParticulier(filialeToUpdate)">
              <v-col cols="2">
                <v-select
                  placeholder="Séléctionnez"
                  :items="genreList"
                  class="select-menu"
                  label="Civilité"
                  :persistent-placeholder="true"
                  v-model="filialeToUpdate.civilite"
                  no-data-text="Aucune civilité trouvée"
                  outlined
                  dense
                  :auto-focus="false"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#704ad1"
                  item-color="#704ad1"
                >
                </v-select>
              </v-col>

              <v-col cols="3">
                <v-text-field
                  label="Prénom *"
                  mess
                  :persistent-placeholder="true"
                  v-model="filialeToUpdate.prenom"
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  label="Nom de famille *"
                  mess
                  :persistent-placeholder="true"
                  v-model="filialeToUpdate.nom_famille"
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
              <v-col cols="2">
                <v-text-field
                  label="Identifiant Pixel"
                  mess
                  :persistent-placeholder="true"
                  v-model="filialeToUpdate.pixel_id"
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
              <v-col cols="2">
                <v-input-colorpicker
                  colorpick-eyedropper-active="false"
                  class="color_picker_width ml-2"
                  v-model="filialeToUpdate.couleur"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  outlined
                  label="Adresse *"
                  v-model="filialeToUpdate.adresse"
                  @input="computedFooterFiliale('duplicate')"
                  :persistent-placeholder="true"
                  rows="3"
                  color="#704ad1"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row v-if="!computedCheckFilialeParticulier(filialeToUpdate)">
              <v-col cols="3">
                <v-select
                  placeholder="Séléctionnez"
                  :items="computedDaysOfMonth(filialeToUpdate.start_month)"
                  class="select-menu"
                  label="Jour début exercice *"
                  no-data-text="Aucun jour trouvé"
                  :persistent-placeholder="true"
                  v-model="filialeToUpdate.start_day"
                  outlined
                  dense
                  :auto-focus="false"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#704ad1"
                  item-color="#704ad1"
                >
                </v-select>
              </v-col>
              <v-col cols="3">
                <v-select
                  placeholder="Séléctionnez"
                  :items="monthList"
                  class="select-menu"
                  label="Mois début exercice *"
                  no-data-text="Aucun mois trouvé"
                  :persistent-placeholder="true"
                  v-model="filialeToUpdate.start_month"
                  outlined
                  dense
                  :auto-focus="false"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#704ad1"
                  item-color="#704ad1"
                >
                </v-select>
              </v-col>
              <v-col cols="3">
                <v-select
                  placeholder="Séléctionnez"
                  :items="computedDaysOfMonth(filialeToUpdate.end_month)"
                  class="select-menu"
                  label="Jour fin exercice *"
                  :persistent-placeholder="true"
                  v-model="filialeToUpdate.end_day"
                  no-data-text="Aucun jour trouvé"
                  outlined
                  dense
                  :auto-focus="false"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#704ad1"
                  item-color="#704ad1"
                >
                </v-select>
              </v-col>
              <v-col cols="3">
                <v-select
                  placeholder="Séléctionnez"
                  :items="monthList"
                  class="select-menu"
                  label="Mois fin exercice *"
                  :persistent-placeholder="true"
                  v-model="filialeToUpdate.end_month"
                  no-data-text="Aucun mois trouvé"
                  outlined
                  dense
                  :auto-focus="false"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#704ad1"
                  item-color="#704ad1"
                >
                </v-select>
              </v-col>
            </v-row>
            <div class="card-style">
              <span class="title-card">Comptabilité</span>
              <v-row class="flex-row-reverse">
                <infoCompta
                  :dataToUse="filialeToUpdate"
                  @validDataCompta="validDataCompta($event, 'filialeToUpdate')"
                ></infoCompta>
              </v-row>
              <v-row>
                <v-col cols="3">
                  <v-text-field
                    label="Journal vente"
                    mess
                    :persistent-placeholder="true"
                    v-model="filialeToUpdate.vente.journal"
                    outlined
                    color="#704ad1"
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="3">
                  <v-text-field
                    label="Client : Compte generale "
                    mess
                    :persistent-placeholder="true"
                    v-model="filialeToUpdate.customer"
                    outlined
                    color="#704ad1"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    label="Compte Prestation vente"
                    mess
                    :persistent-placeholder="true"
                    v-model="filialeToUpdate.vente.prestation"
                    outlined
                    color="#704ad1"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    label="Compte Marchandise vente"
                    mess
                    :persistent-placeholder="true"
                    v-model="filialeToUpdate.vente.marchandise"
                    outlined
                    color="#704ad1"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3">
                  <v-text-field
                    label="Journal achat"
                    mess
                    :persistent-placeholder="true"
                    v-model="filialeToUpdate.achat.journal"
                    outlined
                    color="#704ad1"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    label="Fournisseur : Compte generale "
                    mess
                    :persistent-placeholder="true"
                    v-model="filialeToUpdate.supplier"
                    outlined
                    color="#704ad1"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    label="Compte Prestation achat"
                    mess
                    :persistent-placeholder="true"
                    v-model="filialeToUpdate.achat.prestation"
                    outlined
                    color="#704ad1"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    label="Compte Marchandise achat"
                    mess
                    :persistent-placeholder="true"
                    v-model="filialeToUpdate.achat.marchandise"
                    outlined
                    color="#704ad1"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  <v-checkbox
                    class="checked-global checked-top"
                    v-model="filialeToUpdate.is_detailed"
                    color="#704ad1"
                    :value="true"
                    label="Hors taxes Défalqué par tva"
                    :unchecked-value="0"
                    :true-value="1"
                    :false-value="0"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col cols="4">
                  <v-checkbox
                    class="checked-global checked-top"
                    v-model="filialeToUpdate.is_detailed_ttc"
                    color="#704ad1"
                    :value="true"
                    label="TTC Défalqué par tva"
                    :unchecked-value="0"
                    :true-value="1"
                    :false-value="0"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col cols="4">
                  <v-checkbox
                    class="checked-global checked-top"
                    v-model="filialeToUpdate.is_detailed_reste_charge"
                    color="#704ad1"
                    :value="true"
                    label="Reste à charge défalqué par tva"
                    :unchecked-value="0"
                    :true-value="1"
                    :false-value="0"
                    hide-details
                  ></v-checkbox>
                </v-col>
              </v-row>
            </div>
            <div class="card-style">
              <span class="title-card">Prime délégataire</span>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    label="Journal "
                    mess
                    :persistent-placeholder="true"
                    v-model="filialeToUpdate.prime.journal"
                    outlined
                    color="#704ad1"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    label="Compte prime"
                    mess
                    :persistent-placeholder="true"
                    v-model="filialeToUpdate.prime.compte"
                    outlined
                    color="#704ad1"
                  >
                  </v-text-field>
                </v-col>
                <!-- <v-col cols="4">
                  <v-text-field
                    label="Compte tiers"
                    mess
                    :persistent-placeholder="true"
                    v-model="filialeToUpdate.prime.tiers"
                    outlined
                    color="#704ad1"
                  >
                  </v-text-field>
                </v-col> -->
              </v-row>
            </div>
            <div class="card-style">
              <span class="title-card">Gestion des TVA</span>
              <v-row>
                <v-col cols="2">
                  <v-btn
                    small
                    class="btn-add-setting mr-1"
                    @click="addNewTva('update')"
                  >
                    <font-awesome-icon icon="plus" class="mr-3" /> Ajouter TVA
                  </v-btn>
                </v-col>
              </v-row>
              <v-row v-for="(tva, index) in filialeToUpdate.tva" :key="index">
                <v-col cols="2">
                  <v-text-field
                    label="Tva *"
                    v-model="tva.valeur"
                    :persistent-placeholder="true"
                    required
                    outlined
                    color="#704ad1"
                    :rules="[v => !!v || 'Tva est obligatoire']"
                    class="msg-error"
                    validate-on-blur
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    label="Compte tva vente"
                    v-model="tva.compte_vente"
                    :persistent-placeholder="true"
                    required
                    outlined
                    color="#704ad1"
                    class="msg-error"
                    validate-on-blur
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    label="Compte tva achat"
                    v-model="tva.compte_achat"
                    :persistent-placeholder="true"
                    required
                    outlined
                    color="#704ad1"
                    class="msg-error"
                    validate-on-blur
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="2">
                  <v-text-field
                    label="compte Marchandise(HT)"
                    v-model="tva.compte_marchandise"
                    :persistent-placeholder="true"
                    required
                    outlined
                    color="#704ad1"
                    class="msg-error"
                    validate-on-blur
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    label="Compte prestation(HT)"
                    v-model="tva.compte_prestation"
                    :persistent-placeholder="true"
                    required
                    outlined
                    color="#704ad1"
                    class="msg-error"
                    validate-on-blur
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="1">
                  <v-checkbox
                    label="Activer"
                    class="checked-tva margin-none"
                    color="#704ad1"
                    :value="1"
                    :true-value="1"
                    :false-value="0"
                    hide-details
                    v-model="tva.is_active"
                  ></v-checkbox>
                </v-col>
                <v-col cols="1">
                  <font-awesome-icon
                    icon="times"
                    class="time-icon-style"
                    @click.prevent.stop="deleteTva(index, 'update')"
                  />
                </v-col>
              </v-row>
            </div>
            <v-row>
              <v-col cols="6">
                <v-select
                  placeholder="Séléctionnez"
                  :items="['text', 'image']"
                  class="select-menu"
                  label="Type de footer"
                  :persistent-placeholder="true"
                  v-model="filialeToUpdate.footer_type"
                  @change="filialeToUpdate.footer = null"
                  no-data-text="Aucun type trouvé"
                  outlined
                  dense
                  :auto-focus="false"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#704ad1"
                  item-color="#704ad1"
                >
                </v-select>
              </v-col>
              <v-col cols="6">
                <v-textarea
                  v-if="filialeToUpdate.footer_type == 'text'"
                  outlined
                  label="Footer"
                  v-model="filialeToUpdate.footer"
                  :persistent-placeholder="true"
                  rows="3"
                  color="#704ad1"
                ></v-textarea>
                <v-file-input
                  v-if="filialeToUpdate.footer_type == 'image'"
                  v-model="filialeToUpdate.footer"
                  counter
                  label="Footer"
                  placeholder="Aucun fichier selectionné"
                  :persistent-placeholder="true"
                  prepend-icon="mdi-paperclip"
                  outlined
                  :show-size="1000"
                  color="#704ad1"
                >
                  <template v-slot:selection="{ text }">
                    <v-chip label small>
                      {{ text }}
                    </v-chip>
                  </template>
                </v-file-input>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  :label="'Code postal' + requiredP(filialeToUpdate)"
                  :persistent-placeholder="true"
                  v-model="filialeToUpdate.code_postal"
                  @input="computedFooterFiliale('duplicate')"
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  :label="'Ville' + requiredP(filialeToUpdate)"
                  :persistent-placeholder="true"
                  v-model="filialeToUpdate.ville"
                  @input="computedFooterFiliale('duplicate')"
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  label="Email *"
                  :persistent-placeholder="true"
                  v-model="filialeToUpdate.email"
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  :label="'Abréviation' + requiredP(filialeToUpdate)"
                  :persistent-placeholder="true"
                  v-model="filialeToUpdate.abreviation"
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  :label="'Siren' + requiredP(filialeToUpdate)"
                  :persistent-placeholder="true"
                  v-model="filialeToUpdate.siren_value"
                  @input="computedFooterFiliale('duplicate')"
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Tva"
                  :persistent-placeholder="true"
                  v-model="filialeToUpdate.tva_value"
                  @input="computedFooterFiliale('update')"
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  label="Capital"
                  :persistent-placeholder="true"
                  v-model="filialeToUpdate.capital"
                  @input="computedFooterFiliale('duplicate')"
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="RCS"
                  :persistent-placeholder="true"
                  v-model="filialeToUpdate.rcs"
                  @input="computedFooterFiliale('duplicate')"
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="6"
                v-if="!computedCheckFilialeParticulier(filialeToUpdate)"
              >
                <v-text-field
                  v-if="!computedCheckFilialeParticulier(filialeToUpdate)"
                  label="Site internet"
                  :persistent-placeholder="true"
                  v-model="filialeToUpdate.site_internet"
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
              <v-col
                :cols="
                  !computedCheckFilialeParticulier(filialeToUpdate) ? '6' : '12'
                "
              >
                <v-select
                  placeholder="Séléctionnez"
                  :items="computedListPays"
                  class="select-menu"
                  label="Pays"
                  :persistent-placeholder="true"
                  v-model="filialeToUpdate.pays"
                  outlined
                  dense
                  :auto-focus="false"
                  no-data-text="Aucune pays trouvée"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#704ad1"
                  item-color="#704ad1"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                :cols="
                  !computedCheckFilialeParticulier(filialeToUpdate) ? '6' : '12'
                "
              >
                <v-text-field
                  label="Siret"
                  :persistent-placeholder="true"
                  v-model="filialeToUpdate.siret"
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
              <v-col
                cols="6"
                v-if="!computedCheckFilialeParticulier(filialeToUpdate)"
              >
                <v-text-field
                  label="Fax"
                  :persistent-placeholder="true"
                  v-model="filialeToUpdate.fax"
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-autocomplete
                  placeholder="Séléctionnez"
                  :items="computedTypeReglement"
                  class="select-menu"
                  label="Type Règlement"
                  :persistent-placeholder="true"
                  v-model="filialeToUpdate.payment_type"
                  outlined
                  dense
                  :auto-focus="false"
                  no-data-text="Aucun type règlement trouvée"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#704ad1"
                  item-color="#704ad1"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="6">
                <v-autocomplete
                  placeholder="Séléctionnez"
                  :items="computedConditionPaiement"
                  class="select-menu"
                  label="Condition du paiement"
                  :persistent-placeholder="true"
                  v-model="filialeToUpdate.payment_condition"
                  outlined
                  dense
                  :auto-focus="false"
                  no-data-text="Aucune condition trouvée"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#704ad1"
                  item-color="#704ad1"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                :cols="
                  computedCheckFilialeParticulier(filialeToUpdate) ? '6' : '12'
                "
              >
                <v-text-field
                  label="Téléphone"
                  :persistent-placeholder="true"
                  v-model="filialeToUpdate.telephone"
                  outlined
                  type="number"
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
              <v-col
                cols="6"
                v-if="computedCheckFilialeParticulier(filialeToUpdate)"
              >
                <v-text-field
                  label="Téléphone portable"
                  :persistent-placeholder="true"
                  v-model="filialeToUpdate.telephone_portable"
                  outlined
                  type="number"
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  outlined
                  label="Description"
                  v-model="filialeToUpdate.description"
                  :persistent-placeholder="true"
                  rows="3"
                  color="#704ad1"
                ></v-textarea>
              </v-col>
            </v-row>
            <div
              class="card-style"
              v-if="
                filialeToUpdate.type !== 'master filiale' &&
                  !computedCheckFilialeParticulier(filialeToUpdate)
              "
            >
              <v-row>
                <v-col cols="2" v-if="computedGetLastDateInList !== null">
                  <v-btn
                    small
                    class="btn-add-setting mr-1"
                    @click="addNewMasterFiliale('update')"
                  >
                    <font-awesome-icon icon="plus" class="mr-3" /> Ajouter un
                    Master Filiale
                  </v-btn>
                </v-col>
              </v-row>
              <v-row
                v-for="(master, index) in filialeToUpdate.masterFiliales"
                :key="index"
              >
                <v-col cols="4">
                  <v-select
                    placeholder="Séléctionnez"
                    :items="computedgetMasterFilialeList"
                    class="select-menu"
                    label="Nom master filiale *"
                    item-text="name"
                    item-value="master_id"
                    :persistent-placeholder="true"
                    v-model="master.master_id"
                    outlined
                    dense
                    :auto-focus="false"
                    no-data-text="Aucune Master trouvée"
                    :menu-props="{ bottom: true, offsetY: true }"
                    color="#704ad1"
                    item-color="#704ad1"
                    :disabled="index > 0"
                  >
                  </v-select>
                </v-col>
                <v-col cols="3">
                  <v-menu :close-on-content-click="true" offset-y>
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="master.date_debut"
                        label="Date début"
                        outlined
                        readonly
                        hide-details
                        v-on="on"
                        required
                        clearable
                        :persistent-placeholder="true"
                        placeholder="Sélectionner un date"
                        prepend-inner-icon="mdi-calendar"
                        :autofocus="false"
                        color="#704ad1"
                        :disabled="index > 0"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      color="#704ad1"
                      header-color="#704ad1"
                      event-color="704ad1"
                      v-model="master.date_debut"
                      no-title
                      :disabled="index > 0"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="3">
                  <v-menu :close-on-content-click="true" offset-y>
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="master.date_fin"
                        label="Date fin"
                        outlined
                        readonly
                        hide-details
                        v-on="on"
                        required
                        clearable
                        :persistent-placeholder="true"
                        placeholder="Sélectionner un date"
                        prepend-inner-icon="mdi-calendar"
                        :autofocus="false"
                        color="#704ad1"
                        :disabled="index > 0"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      color="#704ad1"
                      header-color="#704ad1"
                      event-color="704ad1"
                      :disabled="index > 0"
                      no-title
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="1">
                  <font-awesome-icon
                    icon="times"
                    class="time-icon-style"
                    @click="deleteMasterFiliale(index, 'add')"
                  />
                </v-col>
              </v-row>
            </div>
            <v-row v-if="!computedCheckFilialeParticulier(filialeToUpdate)">
              <v-col cols="12">
                <v-autocomplete
                  color="#704ad1"
                  item-color="#704ad1"
                  v-model="filialeToUpdate.responsables"
                  :items="computedChangeResponsable"
                  placeholder="Séléctionnez"
                  :persistent-placeholder="true"
                  label="Support(s)"
                  multiple
                  clearable
                  item-text="full_name"
                  item-value="id"
                  :auto-focus="false"
                  no-data-text="Aucune responsable trouvé."
                  outlined
                >
                  <template v-slot:selection="data">
                    <v-chip
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      close
                      @click="data.select"
                      @click:close="remove(data.item, 'update')"
                    >
                      {{ data.item.name }}
                    </v-chip>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <div
              class="card-style"
              v-if="!computedCheckFilialeParticulier(filialeToUpdate)"
            >
              <v-row>
                <v-col cols="12">
                  <v-file-input
                    v-model="filialeToUpdate.image"
                    counter
                    label="Logo Filiale"
                    placeholder="Aucun Logo selectionné"
                    :persistent-placeholder="true"
                    prepend-icon="mdi-paperclip"
                    outlined
                    :show-size="1000"
                    color="#704ad1"
                  >
                    <template v-slot:selection="{ text }">
                      <v-chip label small>
                        {{ text }}
                      </v-chip>
                    </template>
                  </v-file-input>
                </v-col>
              </v-row>
            </div>

            <v-row v-if="!computedCheckFilialeParticulier(filialeToUpdate)">
              <v-col cols="3">
                <v-checkbox
                  class="checked-global checked-top"
                  v-model="filialeToUpdate.volant"
                  color="#704ad1"
                  :value="true"
                  label="Volant"
                  :unchecked-value="false"
                  @change="checkVolant"
                  :true-value="true"
                  :false-value="false"
                  hide-details
                ></v-checkbox>
              </v-col>
              <v-col cols="6" v-if="filialeToUpdate.volant == true">
                <v-text-field
                  label="Responsable de dépôt"
                  :persistent-placeholder="true"
                  v-model="filialeToUpdate.responsable_depot"
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row
              v-if="
                filialeToUpdate.volant == false &&
                  !computedCheckFilialeParticulier(filialeToUpdate)
              "
            >
              <v-col cols="12">
                <v-autocomplete
                  color="#704ad1"
                  item-color="#704ad1"
                  v-model="filialeToUpdate.depots"
                  :items="getDepotsTh"
                  placeholder="Séléctionnez"
                  :persistent-placeholder="true"
                  label="Dépôt"
                  multiple
                  clearable
                  return-object
                  item-text="depot"
                  item-value="id"
                  :auto-focus="false"
                  no-data-text="Aucune dépôt trouvé."
                  outlined
                >
                  <template v-slot:selection="data">
                    <v-chip
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      close
                      @click="data.select"
                      @click:close="removeDepot(data.item, 'update')"
                    >
                      {{ data.item.depot }}
                    </v-chip>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row
              v-show="
                filialeToUpdate.volant == false &&
                  !computedCheckFilialeParticulier(filialeToUpdate)
              "
              v-for="depot in filialeToUpdate.depots"
              :key="'depot' + depot.id"
            >
              <v-col cols="2">
                <p class="ml-10">
                  {{ depot.depot }}
                </p>
              </v-col>
              <v-col cols="10">
                <v-text-field
                  label="Responsable de dépôt"
                  :persistent-placeholder="true"
                  v-model="depot.responsable_depot"
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <div
              class="card-style"
              v-if="
                filialeToUpdate.volant == false &&
                  !computedCheckFilialeParticulier(filialeToUpdate)
              "
            >
              <v-row
                v-if="
                  filialeToUpdate.volant == false &&
                    !computedCheckFilialeParticulier(filialeToUpdate)
                "
              >
                <v-col cols="12">
                  <v-file-input
                    v-model="filialeToUpdate.files"
                    counter
                    label="Fichiers"
                    multiple
                    placeholder="Aucun fichier selectionné"
                    :persistent-placeholder="true"
                    prepend-icon="mdi-paperclip"
                    outlined
                    :show-size="1000"
                    color="#704ad1"
                  >
                    <template v-slot:selection="{ text }">
                      <v-chip label small>
                        {{ text }}
                      </v-chip>
                    </template>
                  </v-file-input>
                </v-col>
              </v-row>
              <div
                v-if="filialeToUpdate.files && filialeToUpdate.files.length > 0"
              >
                <v-row
                  v-for="(file, index) in filialeToUpdate.files"
                  :key="'file' + index"
                >
                  <v-col cols="4"> {{ file.name }}</v-col>
                  <v-col cols="6">
                    <v-text-field
                      outlined
                      label="Nom du fichier"
                      v-model="file.name_file"
                      :persistent-placeholder="true"
                      required
                      color="#704ad1"
                    ></v-text-field
                  ></v-col>
                  <v-col cols="2">
                    <div
                      @click.prevent.stop="deleteFileDuplicate(index)"
                      title="Supprimer fichier"
                      class="delete-file ml-2"
                    >
                      <font-awesome-icon icon="trash" />
                    </div>
                  </v-col>
                </v-row>
              </div>
            </div>
          </form>
          <div v-if="error" class="message-error-modal">
            <ul v-if="Array.isArray(error)" class="mb-0">
              <li v-for="(e, index) in error" :key="index">
                {{ e }}
              </li>
            </ul>
            <div class="message-error-modal" v-else>{{ error }}</div>
          </div>
        </v-card-text>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            text
            @click.prevent="handleDuplicateFiliale"
            :loading="isLoading"
            :disabled="isLoading"
            :class="{ loader: isLoading }"
          >
            Valider
          </v-btn>
          <v-btn text @click="handleModalFiliale('duplicate', 'hide')">
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <refreshCompta
      :dialogConta="dialogConta"
      :id="oldFilialeToUpdate.id"
      v-if="oldFilialeToUpdate"
      @refreshCompta="refreshCompta"
    ></refreshCompta>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import InputColorPicker from 'vue-native-color-picker';

export default {
  data() {
    return {
      dialogConta: false,
      selectedTable: [],
      uniqueArrayOfCountries: [],
      monthList: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      page: 1,
      perPage: 50,
      perPageList: [
        { value: 50, text: 50 },
        { value: 100, text: 100 },
        { value: 200, text: 200 }
      ],
      typeFilter: null,
      searchValue: null,
      optionsTypeForFitre: [
        { text: 'Tous', value: null },
        { text: 'Professionnel', value: 'professionnel' },
        { text: 'Particulier', value: 'particulier' },
        { text: 'Organisme', value: 'organisme' },
        { text: 'SCI', value: 'sci' },
        { text: 'Master Filiale', value: 'master filiale' }
      ],
      optionTypeSte: [
        { text: 'Tous', value: null },
        { text: 'EURL', value: 'EURL' },
        { text: 'SASU', value: 'SASU' },
        { text: 'SARL', value: 'SARL' },
        { text: 'SA', value: 'SA' },
        { text: 'SAS', value: 'SAS' },
        { text: 'SCP', value: 'SCP' },
        { text: 'SCS', value: 'SCS' },
        { text: 'SCA', value: 'SCA' },
        { text: 'SEL', value: 'SEL' }
      ],
      genreList: ['M.', 'Mme', 'Mmes', 'Mrs', 'M & Mme'],
      deleted: 'no',
      restaurerModal: false,
      updateModalFiliale: false,
      newFiles: [],
      addModalFiliale: false,
      deleteModalFiliale: false,
      duplicateModalFiliale: false,
      isLoading: false,
      steToRestaurer: null,
      error: null,
      filialeToDelete: null,
      filialeToUpdate: null,
      oldFilialeToUpdate: null,
      filialeToAdd: {
        pixel_id: null,
        payment_condition: null,
        payment_type: null,
        couleur: '#704ad1',
        type: 'professionnel',
        name: null,
        depots: [],
        type_societe: null,
        volant: false,
        abreviation: null,
        responsable_depot: null,
        image: null,
        imageData: '',
        dejaVolant: false,
        siret: null,
        adresse: null,
        responsables: [],
        prenom: null,
        civilite: null,
        nom_famille: null,
        siren_value: null,
        capital: null,
        rcs: null,
        tva_value: null,
        site_internet: null,
        ville: null,
        pays: null,
        iban: null,
        banque: null,
        swift: null,
        fax: null,
        telephone: null,
        description: null,
        telephone_portable: null,
        masterFiliales: [],
        Files: [],
        ribs: [],
        is_societe_support: false,
        footer_type: 'text',
        footer: null,
        start_day: null,
        start_month: null,
        end_day: null,
        end_month: null,
        in_configs: 'non',
        customer: null,
        supplier: null,
        achat: {
          marchandise: null,
          prestation: null,
          journal: null
        },
        vente: {
          marchandise: null,
          prestation: null,
          journal: null
        },
        is_detailed: 0,
        is_detailed_ttc: 0,
        is_detailed_reste_charge: 0,
        tva: [],
        prime: {
          compte: null,
          journal: null
        }
      },
      loadingExport: false,
      options: [
        { text: 'Professionnel', value: 'professionnel' },
        { text: 'Particulier', value: 'particulier' },
        { text: 'Organisme', value: 'organisme' },
        { text: 'SCI', value: 'sci' },
        { text: 'Master Filiale', value: 'master filiale' }
      ]
    };
  },
  methods: {
    ...mapActions([
      'getAllTypesReglement',
      'getAllConditionsPaiement',
      'getFamilles',
      'AddSettingFilialeTh',
      'getSettingFilialeTh',
      'deleteSettingFilialeTh',
      'fetchDepotsTh',
      'updateSettingFilialeTh',
      'fetchResponsableFilialeFreeTh',
      'getAllCountreies',
      'getAllTypeSocieteSupport',
      'fetchSequenceFactureFields',
      'getAllMasterFilials',
      'restoreFiliale',
      'getAllFilialesListe',
      'fetchAllTypeSociete',
      'removeFilialeFile',
      'renameFilialeFile',
      'exportListFilialeTh'
    ]),
    validDataCompta(data, type) {
      //tva data
      this[type].tva = data.tva;
      //vente data
      this[type].vente.journal = data.vente.journal;
      this[type].vente.marchandise = data.vente.marchandise;
      this[type].vente.prestation = data.vente.prestation;
      //achat data
      this[type].achat.journal = data.achat.journal;
      this[type].achat.marchandise = data.achat.marchandise;
      this[type].achat.prestation = data.achat.prestation;
      //prime data
      this[type].prime.journal = data.prime.journal;
      this[type].prime.compte = data.prime.compte;
      //client / fournissaire data
      this[type].customer = data.customer;
      this[type].supplier = data.supplier;
    },
    async exportFiles() {
      this.loadingExport = true;
      const response = await this.exportListFilialeTh();
      if (response.success) {
        this.$alert('', response.msg, 'success');
      } else {
        this.$alert('', 'Une erreur est survenue', 'danger');
      }
      this.loadingExport = false;
    },
    computedFooterFiliale(method) {
      if (method === 'add') {
        if (
          this.filialeToAdd.type != 'particulier' ||
          this.filialeToAdd.type != 'passage'
        ) {
          let footer = '';
          if (this.filialeToAdd.type_societe) {
            this.getTypeSociete.map(element => {
              if (element.id == this.filialeToAdd.type_societe) {
                footer += element.type + ' , ';
              }
            });
          }
          if (this.filialeToAdd.name) {
            footer += this.filialeToAdd.name + ' , ';
          }
          if (this.filialeToAdd.adresse) {
            footer += this.filialeToAdd.adresse + ' ';
          }
          if (this.filialeToAdd.code_postal) {
            footer += this.filialeToAdd.code_postal + ' ';
          }
          if (this.filialeToAdd.ville) {
            footer += this.filialeToAdd.ville + '\n';
          }
          if (this.filialeToAdd.capital) {
            footer += this.filialeToAdd.capital + ' , ';
          }
          if (this.filialeToAdd.siren_value) {
            footer += this.filialeToAdd.siren_value + ' , ';
          }
          if (this.filialeToAdd.tva_value) {
            footer += this.filialeToAdd.tva_value + ' , ';
          }
          if (this.filialeToAdd.rcs) {
            footer += this.filialeToAdd.rcs;
          }
          this.filialeToAdd.footer = footer;
        }
      } else {
        if (
          this.filialeToUpdate.type != 'particulier' ||
          this.filialeToUpdate.type != 'passage'
        ) {
          let footer = '';
          if (this.filialeToUpdate.type_societe) {
            this.getTypeSociete.map(element => {
              if (element.id == this.filialeToUpdate.type_societe) {
                footer += element.type + ' , ';
              }
            });
          }
          if (this.filialeToUpdate.name) {
            footer += this.filialeToUpdate.name + ' , ';
          }
          if (this.filialeToUpdate.adresse) {
            footer += this.filialeToUpdate.adresse + ' ';
          }
          if (this.filialeToUpdate.code_postal) {
            footer += this.filialeToUpdate.code_postal + ' ';
          }
          if (this.filialeToUpdate.ville) {
            footer += this.filialeToUpdate.ville + '\n';
          }
          if (this.filialeToUpdate.capital) {
            footer += this.filialeToUpdate.capital + ' , ';
          }
          if (this.filialeToUpdate.siren_value) {
            footer += this.filialeToUpdate.siren_value + ' , ';
          }
          if (this.filialeToUpdate.tva_value) {
            footer += this.filialeToUpdate.tva_value + ' , ';
          }
          if (this.filialeToUpdate.rcs) {
            footer += this.filialeToUpdate.rcs;
          }
          this.filialeToUpdate.footer = footer;
        }
      }
    },

    deleteFileDuplicate(index) {
      this.filialeToUpdate.files.splice(index, 1);
    },
    async handleRenameFile(file, filiale, index) {
      const response = await this.renameFilialeFile({
        file: file,
        newName: file.name,
        index: index,
        item: filiale
      });
    },
    async deleteFile(item, index) {
      if (item.hasOwnProperty('id')) {
        const response = await this.removeFilialeFile(item.id);
        if (response) {
          this.filialeToUpdate.files.splice(index, 1);
        }
      } else {
        this.newFiles.splice(index, 1);
      }
    },
    handleClickDuplicate(item) {
      this.filialeToUpdate = { ...item };
      this.filialeToUpdate.files = [];
      this.handleModalFiliale('duplicate', 'show');
    },
    async handleSubmitUpdateFiliale() {
      this.error = null;
      let ArrayOfStartDate = this?.filialeToUpdate?.masterFiliales?.filter(
        item => item.date_debut === null
      );
      if (ArrayOfStartDate?.length) {
        this.error = 'Date début des masters filiales obligatoire';
        return;
      }
      let ArrayOfTvaValue = this.filialeToUpdate.tva.filter(
        item => item.valeur === null
      );
      if (ArrayOfTvaValue.length) {
        this.error = 'valeur de TVA obligatoire';
        return;
      }
      let testTvaChange = false;
      this.filialeToUpdate.tva.map(i => {
        if (i.id) {
          let datat = this.oldFilialeToUpdate.tva.filter(o => o.id == i.id)[0];
          if (
            datat.compte_vente != i.compte_vente ||
            datat.compte_achat != i.compte_achat ||
            datat.is_active != i.is_active ||
            datat.compte_marchandise != i.compte_marchandise ||
            datat.compte_prestation != i.compte_prestation
          ) {
            testTvaChange = true;
            return;
          }
        }
      });

      if (
        this.filialeToUpdate.vente.journal !=
          this.oldFilialeToUpdate.vente.journal ||
        this.filialeToUpdate.vente.prestation !=
          this.oldFilialeToUpdate.vente.prestation ||
        this.filialeToUpdate.vente.marchandise !=
          this.oldFilialeToUpdate.vente.marchandise ||
        this.filialeToUpdate.customer != this.oldFilialeToUpdate.customer ||
        this.filialeToUpdate.supplier != this.oldFilialeToUpdate.supplier ||
        this.filialeToUpdate.is_detailed !=
          this.oldFilialeToUpdate.is_detailed ||
        this.filialeToUpdate.is_detailed_ttc !=
          this.oldFilialeToUpdate.is_detailed_ttc ||
        this.filialeToUpdate.is_detailed_reste_charge !=
          this.oldFilialeToUpdate.is_detailed_reste_charge ||
        testTvaChange ||
        this.filialeToUpdate.prime.journal !=
          this.oldFilialeToUpdate.prime.journal ||
        this.filialeToUpdate.prime.compte !=
          this.oldFilialeToUpdate.prime.compte ||
        this.filialeToUpdate.achat.journal !=
          this.oldFilialeToUpdate.achat.journal ||
        this.filialeToUpdate.achat.marchandise !=
          this.oldFilialeToUpdate.achat.marchandise ||
        this.filialeToUpdate.achat.prestation !=
          this.oldFilialeToUpdate.achat.prestation
      ) {
        this.dialogConta = true;
        this.updateModalFiliale = false;
      } else {
        this.confirmUpdateCompany();
      }
    },
    refreshCompta(dataRefresh) {
      this.confirmUpdateCompany(dataRefresh);
    },
    async confirmUpdateCompany(dataRefresh) {
      this.updateModalFiliale = true;
      if (dataRefresh) {
        this.dialogConta = false;
      }
      this.isLoading = true;
      const response = await this.updateSettingFilialeTh({
        filiale: this.filialeToUpdate,
        oldFiliale: this.oldFilialeToUpdate,
        newFiles: this.newFiles,
        dataRefresh: dataRefresh
      });
      if (response.success) {
        if (this.filialeToUpdate.type === 'master filiale') {
          this.getAllMasterFilials();
        }
        this.error = null;
        this.isLoading = false;
        this.handleModalFiliale('update', 'hide');
        this.$alert('', 'Sociéte crée avec succès', 'success');
      } else {
        this.error = response.error;
        this.isLoading = false;
      }
    },
    async handleDuplicateFiliale() {
      this.error = null;
      let ArrayOfStartDate = this.filialeToUpdate.masterFiliales.filter(
        item => item.date_debut === null
      );
      if (ArrayOfStartDate.length) {
        this.error = 'Date début des masters filiales obligatoire';
        return;
      }
      let ArrayOfTvaValue = this.filialeToUpdate.tva.filter(
        item => item.valeur === null
      );
      if (ArrayOfTvaValue.length) {
        this.error = 'valeur de TVA obligatoire';
        return;
      }
      this.isLoading = true;
      const response = await this.AddSettingFilialeTh(this.filialeToUpdate);
      if (response.success) {
        this.error = null;
        this.isLoading = false;
        this.handleModalFiliale('duplicate', 'hide');
      } else {
        this.error = response.error;
        this.isLoading = false;
      }
    },
    async handleClickUpdate(item) {
      this.filialeToUpdate = JSON.parse(JSON.stringify(item));
      this.oldFilialeToUpdate = item;
      this.handleModalFiliale('update', 'show');
    },
    onChangeTypeForm(typeParam) {
      this.filialeToAdd = {
        payment_condition: null,
        payment_type: null,
        couleur: '#704ad1',
        type: typeParam,
        type_societe: null,
        code_postal: null,
        name: null,
        depots: [],
        volant: false,
        responsable_depot: null,
        image: null,
        imageData: '',
        siret: null,
        adresse: null,
        responsables: null,
        prenom: null,
        civilite: null,
        nom_famille: null,
        siren_value: null,
        capital: null,
        rcs: null,
        tva_value: null,
        site_internet: null,
        ville: null,
        pays: null,
        iban: null,
        banque: null,
        swift: null,
        fax: null,
        telephone: null,
        description: null,
        telephone_portable: null,
        masterFiliales: [],
        Files: [],
        ribs: [],
        extraMails: [],
        footer_type: 'text',
        footer: null,
        start_day: null,
        start_month: null,
        end_day: null,
        end_month: null,
        customer: null,
        supplier: null,
        achat: {
          marchandise: null,
          prestation: null,
          journal: null
        },
        vente: {
          marchandise: null,
          prestation: null,
          journal: null
        },
        is_detailed: 0,
        is_detailed_ttc: 0,
        is_detailed_reste_charge: 0,
        tva: [],
        prime: {
          compte: null,
          journal: null
        }
      };
      this.error = null;
      this.loading = false;
    },
    deleteMasterFiliale(index, type) {
      if (type === 'add') {
        this.filialeToAdd.masterFiliales.splice(index, 1);
      } else {
        this.filialeToUpdate.masterFiliales.splice(index, 1);
      }
    },
    addNewMasterFiliale(type) {
      if (type === 'add') {
        this.filialeToAdd?.masterFiliales?.unshift({
          master_id: null,
          date_debut: null,
          date_fin: null
        });
      }
      if (type === 'update') {
        this.filialeToUpdate?.masterFiliales?.unshift({
          master_id: null,
          date_debut: null,
          date_fin: null
        });
      }
    },

    /*
     * Gestion  TVA
     */
    addNewTva(type) {
      if (type === 'add') {
        this.filialeToAdd?.tva?.unshift({
          valeur: null,
          compte_vente: null,
          compte_achat: null,
          is_active: 1,
          compte_marchandise: null,
          compte_prestation: null
        });
      }
      if (type === 'update') {
        this.filialeToUpdate?.tva?.unshift({
          valeur: null,
          compte_vente: null,
          compte_achat: null,
          is_active: 1,
          compte_marchandise: null,
          compte_prestation: null
        });
      }
    },
    deleteTva(index, type) {
      if (type === 'add') {
        this.filialeToAdd.tva.splice(index, 1);
      } else {
        this.filialeToUpdate.tva.splice(index, 1);
      }
    },

    async handleSubmitFiliale() {
      this.error = null;
      let ArrayOfStartDate = this.filialeToAdd.masterFiliales.filter(
        item => item.date_debut === null
      );
      if (ArrayOfStartDate.length) {
        this.error = 'Date début des masters filiales obligatoire';
        return;
      }
      let ArrayOfTvaValue = this.filialeToAdd.tva.filter(
        item => item.valeur === null
      );
      if (ArrayOfTvaValue.length) {
        this.error = 'valeur de TVA obligatoire';
        return;
      }
      this.isLoading = true;
      const response = await this.AddSettingFilialeTh(this.filialeToAdd);
      if (response.success) {
        if (this.filialeToAdd.type === 'master filiale') {
          this.getAllMasterFilials();
        }
        this.error = null;
        this.isLoading = false;
        this.handleModalFiliale('add', 'hide');
      } else {
        this.error = response.error;
        this.isLoading = false;
      }
    },
    checkVolant() {
      if (this.filialeToAdd.volant == false) {
        this.filialeToAdd.depots = [];
        this.filialeToAdd.files = [];
      }
    },
    remove(item, type) {
      let index;
      if (type == 'add') {
        this.filialeToAdd.responsables.map((responsable, i) => {
          if ((responsable = item.id)) {
            index = i;
          }
        });
        if (index >= 0) this.filialeToAdd.responsables.splice(index, 1);
      }

      if (type == 'update') {
        this.filialeToUpdate.responsables.map((responsable, i) => {
          if ((responsable = item.id)) {
            index = i;
          }
        });
        if (index >= 0) this.filialeToUpdate.responsables.splice(index, 1);
      }
    },
    removeDepot(item, type) {
      if (type == 'add') {
        const index = this.filialeToAdd.depots.indexOf(item.id);
        if (index >= 0) this.filialeToAdd.depots.splice(index, 1);
      }

      if (type == 'update') {
        const index = this.filialeToUpdate.depots.indexOf(item.id);
        if (index >= 0) this.filialeToUpdate.depots.splice(index, 1);
      }
    },
    onChangeSupport(checked, type) {
      if (type == 'add') {
        if (checked === true) {
          this.filialeToAdd.responsables = this.filialeToAdd.responsables.filter(
            item => item.type === 'responsable_support'
          );
        }
      }

      if (type == 'update') {
        if (checked == true) {
          this.filialeToUpdate.responsables = this.filialeToUpdate.responsables.filter(
            item => item.role === 'responsable_support'
          );
        }
      }
    },
    async handleDeleteFiliale() {
      this.isLoading = true;
      const response = this.deleteSettingFilialeTh(this.filialeToDelete);
      if (response) {
        this.isLoading = false;
        this.handleModalFiliale('delete', 'hide');
      }
    },
    deleteFiliale(item) {
      this.filialeToDelete = item;
      this.handleModalFiliale('delete', 'show');
    },
    async handleRestaurer() {
      this.isLoading = true;
      const response = await this.restoreFiliale(this.steToRestaurer.id);
      if (response) {
        this.isLoading = false;
        this.handleModalFiliale('restaurer', 'hide');
      } else {
        this.isLoading = false;
      }
    },
    async handleClickRestaurer(ste) {
      this.steToRestaurer = ste;
      this.handleModalFiliale('restaurer', 'show');
    },
    handlClickActive(data) {
      this.deleted = data;
      this.handleFilter();
    },
    handleModalFiliale(method, action) {
      if (method == 'restaurer') {
        if (action == 'show') {
          this.restaurerModal = true;
        } else {
          this.restaurerModal = false;
          this.resetModal();
        }
      }
      if (method == 'update') {
        if (action == 'show') {
          this.updateModalFiliale = true;
        } else {
          this.updateModalFiliale = false;
          this.resetModal();
        }
      }
      if (method == 'add') {
        if (action == 'show') {
          this.addModalFiliale = true;
        } else {
          this.addModalFiliale = false;
          this.resetModal();
        }
      }
      if (method == 'delete') {
        if (action == 'show') {
          this.deleteModalFiliale = true;
        } else {
          this.deleteModalFiliale = false;
          this.resetModal();
        }
      }
      if (method == 'duplicate') {
        if (action == 'show') {
          this.duplicateModalFiliale = true;
        } else {
          this.duplicateModalFiliale = false;
          this.resetModal();
        }
      }
    },
    resetModal() {
      this.filialeToAdd = {
        pixel_id: null,
        payment_condition: null,
        payment_type: null,
        couleur: '#704ad1',
        type: 'professionnel',
        type_societe: null,
        name: null,
        depots: [],
        abreviation: null,
        volant: false,
        responsable_depot: null,
        image: null,
        imageData: '',
        siret: null,
        adresse: null,
        responsables: [],
        prenom: null,
        civilite: null,
        nom_famille: null,
        siren_value: null,
        capital: null,
        rcs: null,
        tva_value: null,
        site_internet: null,
        ville: null,
        pays: null,
        iban: null,
        banque: null,
        swift: null,
        fax: null,
        telephone: null,
        description: null,
        telephone_portable: null,
        masterFiliales: [],
        Files: [],
        ribs: [],
        extraMails: [],
        is_societe_support: false,
        footer_type: 'text',
        footer: null,
        start_day: null,
        start_month: null,
        end_day: null,
        end_month: null,
        in_configs: 'non',
        customer: null,
        supplier: null,
        achat: {
          marchandise: null,
          prestation: null,
          journal: null
        },
        vente: {
          marchandise: null,
          prestation: null,
          journal: null
        },
        is_detailed: 0,
        is_detailed_ttc: 0,
        is_detailed_reste_charge: 0,
        tva: [],
        prime: {
          compte: null,
          journal: null
        }
      };
      this.oldFilialeToUpdate = null;
      this.filialeToUpdate = null;
      this.newFiles = [];
      this.error = null;
      this.loading = false;
    },
    async onChangeType() {
      this.typeSte = null;
      this.handleFilter();
      this.setLocalStorageParamsFilialeTh();
    },
    async onChangeTypeSte() {
      this.handleFilter();
      this.setLocalStorageParamsFilialeTh();
    },
    setLocalStorageParamsFilialeTh() {
      localStorage.setItem(
        'Params-gestion-filiale-th',
        JSON.stringify({
          type: this.typeFilter,
          type_societe: this.typeSte,
          search: this.searchValue
        })
      );
    },
    async handleFilter() {
      await this.getSettingFilialeTh({
        per_page: this.perPage,
        page: this.page,
        type: this.typeFilter,
        type_societe: this.typeSte,
        search: this.searchValue,
        deleted: this.deleted
      });
    },
    async pagination(paginate) {
      this.page = paginate;
      this.handleFilter();
    },
    async changePerPage() {
      this.page = 1;
      this.handleFilter();
    },
    changeSearchValue(value) {
      this.searchValue = value;
      this.handleFilter();
    }
  },
  computed: {
    ...mapGetters([
      'TypeReglement',
      'ConditionPaiement',
      'getLoadingSettingFilialeTh',
      'getSettingFilialesTh',
      'getErrorSettingFilialeTh',
      'getDepotsTh',
      'getResponsableFilialeFreeTh',
      'getPaysList',
      'getTypeSocieteSupportTh',
      'getMasterFilialeList',
      'getTotalFiliale',
      'checkPermission',
      'getTypeSociete',
      'getShowNotifExportFile'
    ]),

    computedListPays() {
      let countryNames = [];
      this.getPaysList?.map(element => countryNames.push(element.nom));
      return [...new Set(countryNames)];
    },
    computedGetLastDateInList() {
      return this.filialeToAdd?.masterFiliales[0]?.date_fin;
    },
    computedgetMasterFilialeList() {
      let newListMasterFilials = [];
      newListMasterFilials = this.getMasterFilialeList.map(item => {
        return { name: item.name, master_id: item.id };
      });
      return newListMasterFilials;
    },
    computedChangeResponsable() {
      return this.getResponsableFilialeFreeTh.map(item => {
        return { ...item, name: item.full_name };
      });
    },
    computedConditionPaiement() {
      var table = this.ConditionPaiement.map(item => {
        return {
          text: item.name,
          value: item.id
        };
      });
      return table;
    },
    computedTypeReglement() {
      var table = this.TypeReglement.map(item => {
        return {
          text: item.name,
          value: item.id
        };
      });
      return [{ text: '', value: null }, ...table];
    },
    requiredP() {
      return function(data) {
        return data && data.type == 'particulier' ? '' : '*';
      };
    },
    computedDaysOfMonth() {
      return function(month) {
        var result = [];
        if (month != null) {
          var courantYear = moment().year();
          var resultday = moment(
            courantYear + '-' + month,
            'YYYY-MM'
          ).daysInMonth();
          result = [...Array(resultday - 1 + 1).keys()].map(x => x + 1);
        }
        return result;
      };
    },
    computedSociete() {
      if (this.deleted == 'yes') {
        return this.getSettingFilialesTh?.filter(
          item => item.deleted_at !== null
        );
      }
      return this.getSettingFilialesTh?.filter(item => item.deleted_at == null);
    },
    computedCheckFilialeParticulier() {
      return function(data) {
        return data && data.type == 'particulier';
      };
    },
    totalPages() {
      if (this.getTotalFiliale) {
        return Math.ceil(this.getTotalFiliale / this.perPage);
      }
      return this.getTotalFiliale;
    },
    isActive() {
      return this.deleted === 'no';
    },
    computedFields() {
      let fields = [
        { value: 'name', text: 'Société', show: true },
        { value: 'logo', text: 'Logo', show: this.isActive },
        { value: 'type_societe', text: 'Type de société', show: true },
        { value: 'active_master', text: 'Master Filiale', show: true },
        { value: 'prenom', text: 'Prénom', show: this.isActive },
        {
          value: 'nom_famille',
          text: 'Nom Famille',
          show: this.isActive
        },
        {
          value: 'pixel_id',
          text: 'Identifiant Pixel',
          show: this.isActive
        },
        { value: 'abreviation', text: 'Abréviation', show: true },
        { value: 'type', text: 'Type', show: true },
        { value: 'adresse', text: 'Adresse', show: this.isActive },
        { value: 'email', text: 'Email', show: true },
        { value: 'siret', text: 'Siret', show: this.isActive },
        { value: 'responsable', text: 'Support', show: this.isActive },
        { value: 'depot', text: 'Dépôt', show: this.isActive },
        {
          value: 'responsable_depot',
          text: 'Responsable de dépôt',
          show: this.isActive
        },
        { value: 'civilite', text: 'Civilité', show: this.isActive },
        { value: 'siren_value', text: 'Siren', show: this.isActive },
        { value: 'tva_value', text: 'Tva', show: this.isActive },
        { value: 'capital', text: 'Capital', show: this.isActive },
        { value: 'rcs', text: 'CRS', show: this.isActive },
        {
          value: 'code_postal',
          text: 'Code postal',
          show: this.isActive
        },
        {
          value: 'site_internet',
          text: 'Site internet',
          show: this.isActive
        },
        { value: 'ville', text: 'Ville', show: this.isActive },
        { value: 'pays', text: 'Pays', show: this.isActive },
        { value: 'iban', text: 'Iban', show: this.isActive },
        { value: 'banque', text: 'Banque', show: this.isActive },
        {
          value: 'swift',
          text: 'CODE SWIFT (BIC)',
          show: this.isActive
        },
        { value: 'fax', text: 'Fax', show: this.isActive },
        {
          value: 'footer_type',
          text: 'Type de footer',
          show: this.isActive
        },
        { value: 'footer', text: 'Footer', show: this.isActive },
        {
          value: 'telephone_portable',
          text: 'Téléphone portable',
          show: this.isActive
        },
        {
          value: 'description',
          text: 'Description',
          show: this.isActive
        },
        {
          value: 'payment_condition_name',
          text: 'Conditions du paiement',
          show: this.isActive
        },
        {
          value: 'payment_type',
          text: 'Type Règlement',
          show: this.isActive
        },
        {
          value: 'actions',
          text: ' ',
          width: '60px !important',
          show: true,
          class: 'sticky-header-end'
        }
      ];
      return fields.filter(item => item.show == true);
    }
  },
  components: {
    search: () => import('@/views/component/SearchInputComponent.vue'),
    'v-input-colorpicker': InputColorPicker,
    sequences: () => import('./societe/sequences.vue'),
    ribs: () => import('./societe/ribs.vue'),
    societeFactures: () => import('./societe/societeFactures.vue'),
    extraMails: () => import('./societe/extraMails.vue'),
    pseudos: () => import('./societe/pseudos.vue'),
    masterFiliales: () => import('./societe/masterFiliales.vue'),
    refreshCompta: () => import('./societe/refreshCompta.vue'),
    infoCompta: () => import('./societe/infoCompta.vue'),
    planTiers: () => import('./societe/planTiers.vue')
  },
  async mounted() {
    var data = window.myData;
    if (data && data.nom) {
      let local = JSON.parse(localStorage.getItem('Params-gestion-filiale-th'));
      local.search = data.nom;
      localStorage.setItem('Params-gestion-filiale-th', JSON.stringify(local));
      this.searchValue = data.nom;
    }
    this.getFamilles();
    this.getAllConditionsPaiement();
    this.getAllTypesReglement();
    if (localStorage.getItem('Params-gestion-filiale-th')) {
      this.typeFilter = JSON.parse(
        localStorage.getItem('Params-gestion-filiale-th')
      ).type;
      this.typeSte = JSON.parse(
        localStorage.getItem('Params-gestion-filiale-th')
      ).type_societe;
      this.searchValue = JSON.parse(
        localStorage.getItem('Params-gestion-filiale-th')
      ).search;
    } else {
      this.setLocalStorageParamsFilialeTh();
    }
    this.fetchSequenceFactureFields();
    this.getAllTypeSocieteSupport();
    this.fetchDepotsTh('no');
    this.fetchResponsableFilialeFreeTh();
    this.getAllFilialesListe();
    this.getAllMasterFilials();
    this.handleFilter();
    this.getAllCountreies();
    this.fetchAllTypeSociete();
    let countryNames = [];
    this.getPaysList?.map(element => countryNames.push(element.nom));
    this.uniqueArrayOfCountries = [...new Set(countryNames)];
  }
};
</script>
<style lang="scss" scoped>
.delete-file {
  color: red;
  padding-top: 8px;
}

.table-gestion-content {
  max-height: calc(100vh - 253px) !important;
  height: calc(100vh - 253px) !important;
}

.block-setting {
  margin-left: 33%;
}

.block-action {
  // margin-top: 5px;
  min-height: 50px !important;
  // top: 236px;
  // left: 1720px;
  // transform-origin: left top;

  .v-list-item__title {
    font-size: 12px !important;
  }

  .v-list-item {
    min-height: 30px !important;
    padding: 0px 12px !important;
  }

  .v-list-item__content {
    padding: 0px 0px !important;
  }
}

.image-preview-inside {
  position: relative;
  display: inline;
  max-width: 250px;
}

.card-style {
  border-radius: 10px;
  border: 1px solid #b5b5b5;
  padding: 28px;
  margin-top: 16px;
  margin-bottom: 20px;
  position: relative;

  .title-card {
    position: absolute;
    top: -12px;
    font-size: 12px;
    background-color: #fff;
    padding-left: 5px;
    color: rgb(0 0 0 / 41%);
  }
}

.color_picker_width {
  width: 32px;
  height: 32px;
  border: solid 1px;
  margin: auto;
  display: block;
}

.add-master {
  width: 100% !important;
}

.time-icon-style {
  margin-top: 5px;
  color: red;
  width: 25px;
  height: 25px;
}

.image-preview {
  position: relative;
  display: inline;
  max-width: 112px;
}

.style-box-setting .box-content .card-block-filter-setting .search-input {
  margin-right: 0px !important;
}

.checked-top {
  margin-top: 10px;

  .v-label {
    font-size: 13px !important;
  }
}

.v-card__text.body-card.center-text {
  padding: 0px !important;
}
</style>
<style lang="scss">
.checked-top {
  .v-label {
    font-size: 13px !important;
  }
}
</style>
